let serverUrl;
let socketUrl;

if (process.env.NODE_ENV === "development") {
	// serverUrl = "https://pb-monitor.vercel.app";
	serverUrl = "http://localhost:5115";
	socketUrl = "http://localhost:5115";
}

if (process.env.NODE_ENV === "production") {
	serverUrl = "https://pb-monitor.vercel.app";
	socketUrl = "wss://pb-monitor.vercel.app";
}

const baseURL = `${serverUrl}/api/v1`;

export { serverUrl, baseURL , socketUrl};
