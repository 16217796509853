import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
	getGenderDistribution,
	getPWDDistribution,
} from "services/analyticsService";
import PieChart from "utilities/Charts/PieChart";
import { Divider } from "@mui/material";

function Ditribution({ state, year }) {
	const [gdDistData, setGdDistData] = useState([]);
	const [genderData, setGenderData] = useState({ labels: [], datasets: [] });

	const [pwdDistData, setPwdDistData] = useState([]);
	const [PWDData, setPWDData] = useState({ labels: [], datasets: [] });
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchGenderDist = async () => {
			try {
				const res = await getGenderDistribution(year, state);
				const { data } = res;
				setGdDistData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchGenderDist();
		setLoading(false);
	}, [state, year]);

	useEffect(() => {
		const labels = gdDistData.map((item) => item.state);
		const genders = ["Male", "Female", "Other"];

		const datasets = genders.map((gender) => {
			return {
				label: gender,
				data: gdDistData.map((item) => {
					const gdd = item.genders.find((g) => g.gender === gender);
					return gdd ? gdd.count : 0;
				}),
				backgroundColor: {
					Male: "#36A2EB",
					Female: "#FF6384",
					Other: "#FFCE56",
				}[gender],
			};
		});

		setGenderData({
			labels,
			datasets,
		});
	}, [gdDistData]);

	useEffect(() => {
		const fetchPwdDist = async () => {
			try {
				const res = await getPWDDistribution(year, state);
				const { data } = res;
				setPwdDistData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchPwdDist();
		setLoading(false);
	}, [state, year]);

	useEffect(() => {
		const labels = pwdDistData.map((item) => item.state);
		const answers = ["Yes", "No", "No_say"];

		const datasets = answers.map((ans) => {
			return {
				label: ans,
				data: pwdDistData.map((item) => {
					const pwd = item.PWDs.find((rs) => rs.PWD === ans);
					return pwd ? pwd.count : 0;
				}),
				backgroundColor: {
					Yes: "#4CAF50",
					No: "#F44336",
					No_say: "#FFC107",
				}[ans],
			};
		});

		setPWDData({
			labels,
			datasets,
		});
	}, [pwdDistData]);

	return (
		<MDBox mt={4.5}>
			<MDBox mx={2} mb={3} py={3} px={2}>
				<MDTypography variant="h6" color="dark">
					Clinicians Gender and PWD Distribution
				</MDTypography>
				<Divider />
			</MDBox>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} lg={6}>
					<MDBox mb={3}>
						<PieChart
							icon={{ component: "person", color: "info" }}
							title="Gender Distribution by Location"
							description="A breakdown of gender distribution across different locations."
							chart={genderData}
							date={year}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<MDBox mb={3}>
						<PieChart
							icon={{ component: "person", color: "info" }}
							title="PWD Distribution by Location"
							description="A breakdown of PWD distribution across different locations."
							chart={PWDData}
							date={year}
						/>
					</MDBox>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default Ditribution;
