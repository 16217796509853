import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card } from "@mui/material";
import { toast } from "react-toastify";
import { createCaseConference, viewCaseConferenceReport } from "services/caseConferenceService";
import Editor from "components/Editor";

function CaseUploadView() {
    const { id } = useParams();	
	 const [conferenceLocation, setConferenceLocation] = useState("");
  const [conferenceDate, setConferenceDate] = useState("");
  const [participants, setParticipants] = useState("");
  const [groupMemberParticipants, setGroupMemberParticipants] = useState("");
  const [listOfCasesDiscussed, setListOfCasesDiscussed] = useState("");
  const [outcome, setOutcome] = useState("");
  const [nextSteps, setNextSteps] = useState("");
  const [note, setNote] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [clinics, setClinics] = useState("");
  const [confData, setConfData] = useState(null);

  const fetchData = async () => {
    try {
      const cRes = await viewCaseConferenceReport(id);
      const data = cRes.data;
      
      setClinics(data.nameOfLawClinic)
      setConferenceLocation(data.location.state);
      setConferenceDate(new Date(data.conferenceDate).toISOString().split('T')[0]);
      setParticipants(data.participants.join(", "));
      setGroupMemberParticipants(data.internalParticipants.join(", "));
      setListOfCasesDiscussed(data.listOfCasesDiscussed.join(", "));
      setOutcome(data.outcome);
      setNextSteps(data.nextSteps);
      setNote(data.note);
      setLatitude(data.location.latitude);
      setLongitude(data.location.longitude);
      setConfData(data);
      console.log('fetched case', data);
    } catch (error) {
      console.log("Case conference data fetch_error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

	
	
	return (
		<DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography
                  variant="h6"
                  color="white"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  Case Conference Report Form
                </MDTypography>
              </MDBox>
              <MDBox pt={6} pb={3}>
                <MDBox px={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="date"
                        variant="standard"
                        label="Conference Date"
                        fullWidth
                        value={conferenceDate}
                        onChange={(e) => setConferenceDate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        label="Location State"
                        variant="standard"
                        fullWidth
                        value={conferenceLocation}
                        onChange={(e) => setConferenceLocation(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        variant="standard"
                        label="Latitude"
                        fullWidth
                        value={latitude}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        variant="standard"
                        label="Longitude"
                        fullWidth
                        value={longitude}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                  <MDBox mb={2} mt={3}>
                    
                        <MDInput
                          type="text"
                          label="Clinic Name"
                          value={clinics}
                          variant="standard"
                          fullWidth
                        />
                      
                    
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      variant="standard"
                      label="Conference Location"
                      fullWidth
                      value={conferenceLocation}
                      onChange={(e) => setConferenceLocation(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"Participants"}
                      content={participants}
                      handleInputChange={(e) => setParticipants(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"Group Member Participants"}
                      content={groupMemberParticipants}
                      handleInputChange={(e) => setGroupMemberParticipants(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"List of Cases Discussed"}
                      content={listOfCasesDiscussed}
                      handleInputChange={(e) => setListOfCasesDiscussed(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"Conference Outcome"}
                      content={outcome}
                      handleInputChange={(e) => setOutcome(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"Next Steps"}
                      content={nextSteps}
                      handleInputChange={(e) => setNextSteps(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <Editor
                      title={"Note"}
                      content={note}
                      handleInputChange={(e) => setNote(e.target.value)}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
	);
}

export default CaseUploadView;
