import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";

import Breadcrumbs from "utilities/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
	navbar,
	navbarContainer,
	navbarRow,
	navbarIconButton,
	navbarMobileMenu,
} from "utilities/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
	useAppContextController,
	setTransparentNavbar,
	setMiniSidenav,
	setOpenConfigurator,
} from "context";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAuth } from "context/auth";
import { toast } from "react-toastify";
import { removeToken } from "services/tokenService";
import SignatureModal from "components/signature-modal";

function DashboardNavbar({ absolute, light, isMini }) {
	const authcontext = useAuth();
	const user = authcontext.user;
	const [showModal, setShowModal] = useState(false);
	const [navbarType, setNavbarType] = useState();
	const [controller, dispatch] = useAppContextController();
	const {
		miniSidenav,
		transparentNavbar,
		fixedNavbar,
		openConfigurator,
		darkMode,
	} = controller;
	const [openMenu, setOpenMenu] = useState(false);
	const route = useLocation().pathname.split("/").slice(1);

	useEffect(() => {
		// Setting the navbar type
		if (fixedNavbar) {
			setNavbarType("sticky");
		} else {
			setNavbarType("static");
		}

		// A function that sets the transparent state of the navbar.
		function handleTransparentNavbar() {
			setTransparentNavbar(
				dispatch,
				(fixedNavbar && window.scrollY === 0) || !fixedNavbar,
			);
		}

		/** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
		window.addEventListener("scroll", handleTransparentNavbar);

		// Call the handleTransparentNavbar function to set the state with the initial value.
		handleTransparentNavbar();

		// Remove event listener on cleanup
		return () => window.removeEventListener("scroll", handleTransparentNavbar);
	}, [dispatch, fixedNavbar]);

	const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);
	const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
	const handleCloseMenu = () => setOpenMenu(false);
	const navigate = useNavigate();

	const handleProfileClick = () => {
		navigate("/profile");
		handleCloseMenu();
	};

	const handleLogout = async () => {
		try {
			// const res = await logoutUser();
			// const { message, status } = res;
			// if (status === "success") {
			localStorage.setItem("user", null);
			removeToken();
			authcontext.dispatch({ type: "LOGOUT" });
			navigate("/", { replace: true });
			toast("Logged out", { type: "success", autoClose: 3000 });
			// } else {
			// 	toast(message, { type: "error", autoClose: 3000 });
			// }
		} catch (error) {
			navigate("/", { replace: true });
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	useEffect(() => {
		if (user && user.signature && user.signature.url === "") {
				setShowModal(true);
		}
	}, [user]);

	// Render the notifications menu
	const renderMenu = () => (
		<Menu
			anchorEl={openMenu}
			anchorReference={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={Boolean(openMenu)}
			onClose={handleCloseMenu}
			sx={{ mt: 2 }}
		>
			<MenuItem onClick={handleProfileClick}>
				<ListItemIcon>
					<Icon>person</Icon>
				</ListItemIcon>
				<ListItemText primary="Profile" />
			</MenuItem>
			<MenuItem onClick={handleLogout}>
				<ListItemIcon>
					<Icon>logout</Icon>
				</ListItemIcon>
				<ListItemText primary="Logout" />
			</MenuItem>
		</Menu>
	);

	// Styles for the navbar icons
	const iconsStyle = ({
		palette: { dark, white, text },
		functions: { rgba },
	}) => ({
		color: () => {
			let colorValue = light || darkMode ? white.main : dark.main;

			if (transparentNavbar && !light) {
				colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
			}

			return colorValue;
		},
	});

	return (
		<AppBar
			position={absolute ? "absolute" : navbarType}
			color="inherit"
			sx={(theme) =>
				navbar(theme, { transparentNavbar, absolute, light, darkMode })
			}
		>
			<SignatureModal open={showModal} handleClose={() => setShowModal(false)} />
			<Toolbar sx={(theme) => navbarContainer(theme)}>
				<MDBox
					color="inherit"
					mb={{ xs: 1, md: 0 }}
					sx={(theme) => navbarRow(theme, { isMini })}
				>
					<Breadcrumbs
						icon="home"
						title={route[route.length - 1]}
						route={route}
						light={light}
					/>
				</MDBox>
				{isMini ? null : (
					<MDBox sx={(theme) => navbarRow(theme, { isMini })}>
						<MDBox pr={1}>{/* <MDInput label="Search here" /> */}</MDBox>
						<MDBox color={light ? "white" : "inherit"}>
							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarMobileMenu}
								onClick={handleMiniSidenav}
							>
								<Icon sx={iconsStyle} fontSize="medium">
									{miniSidenav ? "menu_open" : "menu"}
								</Icon>
							</IconButton>
							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarIconButton}
								onClick={handleConfiguratorOpen}
							>
								<Icon sx={iconsStyle}>settings</Icon>
							</IconButton>
							<IconButton
								size="small"
								disableRipple
								color="inherit"
								sx={navbarIconButton}
								aria-controls="notification-menu"
								aria-haspopup="true"
								variant="contained"
								onClick={handleOpenMenu}
							>
								<Icon sx={iconsStyle}>account_circle</Icon>
							</IconButton>
							{renderMenu()}
						</MDBox>
					</MDBox>
				)}
			</Toolbar>
		</AppBar>
	);
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
	absolute: false,
	light: false,
	isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
	absolute: PropTypes.bool,
	light: PropTypes.bool,
	isMini: PropTypes.bool,
};

export default DashboardNavbar;
