import httpService from "./httpService";

export const getAllMembers = async () => {
	try {
		const res = await httpService.get("/member/all");
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getSingleMember = async (id) => {
	try {
		const res = await httpService.get(`/member/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteMember = async (id) => {
	try {
		const res = await httpService.delete(`/member/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateUserData = async (form) => {
	try {
		const response = await httpService.put("/member/profile-update", form);
		return response;
	} catch (err) {
		let errorMessage = "Couldn't update user data, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMyDetails = async () => {
	try {
		const response = await httpService.get("/member/my/details");
		return response;
	} catch (err) {
		let errorMessage = "Couldn't get user data, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
