import httpService from "./httpService";

export const createCaseUpload = async (form) => {
	try {
		const response = await httpService.post(`/case-upload-form/create/`, form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllCaseUploadReport = async (page, limit, keyword, query) => {
	const year = query || new Date().getFullYear();
	let url = `/case-upload-form/reports`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllMyCaseUploadReport = async (page, limit, keyword, query) => {
	const year = query || new Date().getFullYear();
	let url = `/case-upload-form/my/reports`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteCaseUploadReport = async (id) => {
	try {
		const response = await httpService.delete(`/case-upload-form/${id}/delete`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateCaseUploadReport = async (id, payload) => {
	try {
		const response = await httpService.put(
			`/case-upload-form/${id}/update`,
			payload,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewCaseUploadReport = async (id) => {
	try {
		const response = await httpService.get(`/case-upload-form/${id}/view`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
