import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import MDInput from "components/MDInput";
import { updateSignature } from "services/userService";
import { useAuth } from "context/auth";

function SignatureModal({ handleClose, open }) {
		const { dispatch } = useAuth();
	const [selectedFile, setSelectedFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [userSignature, setUserSignature] = useState("");

	useEffect(() => {
		if (selectedFile) {
			const objectUrl = URL.createObjectURL(selectedFile);
			setUserSignature(objectUrl);
			return () => URL.revokeObjectURL(objectUrl);
		}
	}, [selectedFile]);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append("file", selectedFile);
		setIsLoading(true);
		try {
			const res = await updateSignature(formData);
			toast("Signature updated successfully", {
				type: "success",
				autoClose: 3000,
			});
			dispatch({ type: "UPDATE", payload: res?.data });
			setIsLoading(false);
			setSelectedFile(null);
			handleClose();
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>
				Upload Signature
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "text.primary",
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<MDBox sx={{ position: "relative" }}>
			{userSignature ? (
				<MDBox
					 p={3}
					sx={{
						width: "100%",
						height: "100%",
						backgroundImage: `url(${userSignature})`,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
					}}
				/>
			) : (
				<MDInput
				p={3}
					type="text"
					variant="standard"
					readOnly
					fullWidth
					value={"No signature found; click the pen icon to add one"}
				/>
			)}
			<input
				accept="png, jpg, jpeg"
				id="file-input"
				type="file"
				style={{ display: "none" }}
				onChange={handleFileChange}
			/>
			{!isLoading && (
				<label htmlFor="file-input">
					<IconButton
						sx={{
							position: "absolute",
							bottom: 15,
							right: 10,
							transform: "translate(50%, 50%)",
							backgroundColor: "background.paper",
						}}
						component="span"
						aria-label="Edit"
					>
						<EditIcon />
					</IconButton>
				</label>
			)}
		</MDBox>
		<MDBox mt={4} mb={3}>
              
			  <MDButton
			  type="submit"
								variant="gradient"
								color="info"
								fullWidth
								disabled={isLoading || !selectedFile}
								onClick={handleSubmit}
							>
								{isLoading ? "Loading..." : "Update Signature"}
							</MDButton>
            </MDBox>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

export default SignatureModal;
