import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import  html2pdf  from "html2pdf.js";

const PrintableAndDownloadable = ({ children, filename = 'document.pdf' }) => {
   const handlePrint = () => {
    const content = document.getElementById('content').innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    
    const styles = Array.from(document.querySelectorAll('style, link')).map(style => style.outerHTML).join('\n');
    
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          ${styles}
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('content');
    const opt = {
      margin: 1,
      filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <>
      <MDBox
        px={2}
        mt={5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={handlePrint}
        >
          Print
        </MDButton>
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={handleDownloadPDF}
        >
          Download PDF
        </MDButton>
      </MDBox>
      <div id="content">
        {children}
      </div>
    </>
  );
};

export default PrintableAndDownloadable;