import httpService from "./httpService";

export const createDetaineeReport = async (form) => {
	try {
		const response = await httpService.post(`/detainee-form/create/`, form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllMyDetaineeReport = async (page, limit, keyword, query) => {
	const year = query || new Date().getFullYear();
	let url = `/detainee-form/my/reports`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllDetaineeReport = async (page, limit, keyword, query) => {
	const year = query || new Date().getFullYear();
	let url = `/detainee-form/reports`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteDetaineeReport = async (id) => {
	try {
		const response = await httpService.delete(`/detainee-form/${id}/delete`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateDetaineeReport = async (id, payload) => {
	try {
		const response = await httpService.put(
			`/detainee-form/${id}/update`,
			payload,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const addRemarkDetaineeReport = async (id, payload) => {
	try {
		const response = await httpService.patch(
			`/detainee-form/${id}/remark`,
			payload,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewDetaineeReport = async (id) => {
	try {
		const response = await httpService.get(`/detainee-form/${id}/view`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
