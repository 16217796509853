import { Card, Grid } from '@mui/material';
import Editor from 'components/Editor';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import html2pdf  from 'html2pdf.js';
import React, { useState } from 'react'

const CaseConfBlank = () => {
     const [signatureOfClinicGroupHead, setSignatureOfClinicGroupHead] =
        useState("");
    const [signatureOfLawyer, setSignatureOfLawyer] = useState("");
    const [signatureOfSupervisor, setSignatureOfSupervisor] = useState("");
    const [conferenceLocation, setConferenceLocation] = useState("");
    const [conferenceDate, setConferenceDate] = useState(Date.now());
    const [participants, setParticipants] = useState([]);
    const [members, setMembers] = useState([]);
    const [listOfCasesDiscussed, setListOfCasesDiscussed] = useState([""]);
    const [lawyerNames, setLawyerNames] = useState([""]);

    const [note, setNote] = useState("");
    const [locationState, setLocationState] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);



    const handleClinicChange = (event, newValue) => {
        setSelectedClinic(newValue);
    };

    const handleStateChange = (event, value) => {
        setLocationState(value ? value.name : null);
    };

   

    const reverseGeocode = (latitude, longitude) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(latitude, longitude);

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    const state = addressComponents.find((item) =>
                        item.types.includes("administrative_area_level_1")
                    );
                    setLocationState(state.long_name || "Unknown State");
                } else {
                    setLocationState("Please select a state");
                }
                setLoading(false);
            }
        });
    };



    const handleFormClear = () => {
        // Reset form
        setSignatureOfClinicGroupHead("");
        setSignatureOfLawyer("");
        setSignatureOfSupervisor("");
        setSelectedClinic(null);
        setConferenceLocation("");
        setConferenceDate(null);
        setParticipants([]);
        setListOfCasesDiscussed([]);
        setNote("");
        setLocationState("");
    };

    const handleCaseChange = (index, value) => {
        const updatedCases = [...listOfCasesDiscussed];
        updatedCases[index] = value;
        setListOfCasesDiscussed(updatedCases);
    };

    const handleAddCase = () => {
        setListOfCasesDiscussed([...listOfCasesDiscussed, ""]);
    };

    const handleRemoveCase = (index) => {
        const updatedCases = [...listOfCasesDiscussed];
        updatedCases.splice(index, 1);
        setListOfCasesDiscussed(updatedCases);
    };

    const handleNameChange = (index, value) => {
        const updatedNames = [...lawyerNames];
        updatedNames[index] = value;
        setLawyerNames(updatedNames);
    };

    const handleAddName = () => {
        setLawyerNames([...lawyerNames, ""]);
    };

    const handleRemoveName = (index) => {
        const updatedNames = [...lawyerNames];
        updatedNames.splice(index, 1);
        setLawyerNames(updatedNames);
    };
    const handleDownloadPDF = () => {

    const element = document.getElementById('hidden-content');
    const opt = {
      margin: 1,
      filename:'case-conf.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  };
  return (
    <>
     <div style={{ display: 'none' }}>
        <div id="hidden-content">
     <MDBox pt={6} pb={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    Case Conference Report Form
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={6} pb={3}>
                                <MDBox px={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="date"
                                                variant="standard"
                                                label="Conference Date"
                                                fullWidth
                                                value={conferenceDate}
                                                onChange={(e) =>
                                                    setConferenceDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>
                                               
                                                        <MDInput
                                                            type="text"
                                                            label="State of Conference"
                                                            variant="standard"
                                                            fullWidth
                                                        />
                                                 
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Latitude"
                                                fullWidth
                                                value={latitude}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Longitude"
                                                fullWidth
                                                value={longitude}
                                                readOnly
                                            />
                                        </Grid>
                                    </Grid>
                                    <MDBox mb={2} mt={3}>
                                       
                                                <MDInput
                                                    type="text"
                                                    label="Name of Law Clinic"
                                                    variant="standard"
                                                    fullWidth
                                                />
                                         
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <MDInput
                                            type="text"
                                            variant="standard"
                                            label="Location of Case Conference"
                                            fullWidth
                                            value={conferenceLocation}
                                            onChange={(e) =>
                                                setConferenceLocation(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        
                                                <MDInput
                                                    variant="standard"
                                                    label="List of Clinicians Present (Select multiple)"
                                                    fullWidth
                                                />
                                          
                                    </MDBox>
                                    <MDBox mb={2} sx={{ mt: 4 }}>
                                        <MDTypography variant="h6">
                                            List of Cases Discussed and
                                            Outcomes/Next steps
                                        </MDTypography>
                                        {listOfCasesDiscussed.map(
                                            (caseItem, index) => (
                                                <MDBox
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={1}
                                                >
                                                    <MDInput
                                                        type="text"
                                                        variant="standard"
                                                        placeholder="Type case and its outcome here..."
                                                        fullWidth
                                                        value={caseItem}
                                                        onChange={(e) =>
                                                            handleCaseChange(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <MDButton
                                                        variant="text"
                                                        color="error"
                                                        onClick={() =>
                                                            handleRemoveCase(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </MDButton>
                                                </MDBox>
                                            )
                                        )}
                                        <MDButton
                                            variant="outlined"
                                            color="dark"
                                            size="small"
                                            onClick={handleAddCase}
                                        >
                                            Add Case
                                        </MDButton>
                                    </MDBox>

                                    <MDBox mb={2} sx={{ mt: 4 }}>
                                        <MDTypography
                                            variant="h6"
                                            color="textSecondary"
                                        >
                                            Name of Lawyer(s)
                                        </MDTypography>
                                        {lawyerNames.map((name, index) => (
                                            <MDBox
                                                key={index}
                                                display="flex"
                                                alignItems="center"
                                                mb={1}
                                            >
                                                <MDInput
                                                    type="text"
                                                    variant="standard"
                                                    placeholder="Enter lawyer's name..."
                                                    fullWidth
                                                    value={name}
                                                    onChange={(e) =>
                                                        handleNameChange(
                                                            index,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <MDButton
                                                    variant="text"
                                                    color="error"
                                                    onClick={() =>
                                                        handleRemoveName(index)
                                                    }
                                                >
                                                    Remove
                                                </MDButton>
                                            </MDBox>
                                        ))}
                                        <MDButton
                                            variant="outlined"
                                            color="dark"
                                            size="small"
                                            onClick={handleAddName}
                                        >
                                            Add Lawyer
                                        </MDButton>
                                    </MDBox>

                                    <MDBox mb={2}>
                                        <Editor
                                            title={"Notes"}
                                            content={note}
                                            handleInputChange={(e) =>
                                                setNote(e.target.value)
                                            }
                                        />
                                    </MDBox>

                                    <MDBox
                                        mt={3}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        
                                        
                                        
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            </div>
            </div>
            <MDButton
        type="button"
        variant="outlined"
        onClick={handleDownloadPDF}
      >
        Download Case Conference Form
      </MDButton>
    </>
  )
}

export default CaseConfBlank;