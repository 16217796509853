import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "components/MDBox";
import { toast } from "react-toastify";
import MDInput from "components/MDInput";
import { myProfile, updateSignature } from "services/userService";
import { useAuth } from "context/auth";

function Signature() {
	const { user, dispatch } = useAuth();
	const [selectedFile, setSelectedFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [userSignature, setUserSignature] = useState("");

	const fetchMyData = async () => {
		try {
			const res = await myProfile();
			const { data, status, message } = res;
			if (status === "success" && data && data.signature && data.signature.url) {
				setUserSignature(data.signature.url);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchMyData();
	}, []);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	useEffect(() => {
    const uploadHandler = async () => {
      const formData = new FormData();
      formData.append("file", selectedFile);
      setIsLoading(true);
      try {
        const res = await updateSignature(formData);
        const { data, status, message } = res;
        if (status === "success" && data && data.signature && data.signature.url) {
          fetchMyData();
		   dispatch({ type: "LOGIN", payload: { ...user, signature: data.signature } });
        } else {
          toast(message || "Failed to update signature", { type: "error", autoClose: 3000 });
        }
        setIsLoading(false);
        setSelectedFile(null);
      } catch (error) {
        toast(error.message || "An error occurred while updating the signature", { type: "error", autoClose: 3000 });
        setIsLoading(false);
      }
    };
    if (selectedFile) {
      uploadHandler();
    }
  }, [selectedFile]);

	return (
		<MDBox sx={{ position: "relative" }}>
			{userSignature ? (
				<MDBox
					 p={3}
					sx={{
						width: "100%",
						height: "100%",
						backgroundImage: `url(${userSignature})`,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
					}}
				/>
			) : (
				<MDInput
				p={3}
					type="text"
					variant="standard"
					readOnly
					fullWidth
					value={"No signature found; click the pen icon to add one"}
				/>
			)}
			<input
				accept="png, jpg, jpeg"
				id="file-input"
				type="file"
				style={{ display: "none" }}
				onChange={handleFileChange}
			/>
			{!isLoading && (
				<label htmlFor="file-input">
					<IconButton
						sx={{
							position: "absolute",
							bottom: 15,
							right: 10,
							transform: "translate(50%, 50%)",
							backgroundColor: "background.paper",
						}}
						component="span"
						aria-label="Edit"
					>
						<EditIcon />
					</IconButton>
				</label>
			)}
		</MDBox>
	);
}

export default Signature;
