import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { myProfile } from "services/userService";

function Organisation(props) {
	const [name, setName] = useState("");
	const [location, setLocation] = useState("");

	const fetchMyData = async () => {
		try {
			const res = await myProfile();
			const { data } = res;
				setName(data.clinic.name)
				setLocation(data.clinic.location.state)
			
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchMyData();
	}, []);

	return (
		<>
			<MDBox px={2} sx={{ flex: 1 }}>
				<MDBox p={2}>
					<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
						Clinic Information
					</MDTypography>
				</MDBox>
				<MDBox px={2} sx={{ flex: 1 }}>
					<MDBox mb={2}>
						<MDInput
							type="text"
							variant="standard"
							label="Name"
							value={name}
							disabled
							fullWidth
						/>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							value={location}
							label="Location"
							disabled
							variant="standard"
							fullWidth
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
}

export default Organisation;
