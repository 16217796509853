import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/lab/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Cover() {
	const [selectedOrganization, setSelectedOrganization] = useState(null);
	const [newOrganization, setNewOrganization] = useState("");

	const organizations = [
		{ id: "1", name: "Organization 1" },
		{ id: "2", name: "Organization 2" },
		{ id: "3", name: "Organization 3" },
	];

	const handleOrganizationChange = (event, value) => {
		setSelectedOrganization(value);
	};

	const handleNewOrganizationChange = (event) => {
		setNewOrganization(event.target.value);
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		// Here you can use the selectedOrganization or newOrganization values
		console.log(selectedOrganization, newOrganization);
	};

	return (
		<CoverLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Join the Cohort Member
					</MDTypography>
					<MDTypography display="block" variant="button" color="white" my={1}>
						Fill the form below to get started
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form" onSubmit={handleFormSubmit}>
						<MDBox mb={2}>
							<MDInput type="text" label="Full Name" variant="standard" fullWidth />
						</MDBox>
						<MDBox mb={2}>
							<MDInput type="email" label="Email" variant="standard" fullWidth />
						</MDBox>
						<MDBox mb={2}>
							<Autocomplete
								value={selectedOrganization}
								onChange={handleOrganizationChange}
								options={organizations}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<MDInput
										{...params}
										type="text"
										label="Organization"
										variant="standard"
										fullWidth
										onChange={handleNewOrganizationChange}
										value={newOrganization}
									/>
								)}
								freeSolo // Allow typing for new organization
							/>
						</MDBox>
						<MDBox display="flex" alignItems="center" ml={-1}>
							<Checkbox />
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
							>
								&nbsp;&nbsp;I agree to the&nbsp;
							</MDTypography>
							<MDTypography
								component="a"
								href="#"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
							>
								Terms and Conditions
							</MDTypography>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton type="submit" variant="gradient" color="info" fullWidth>
								Submit
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Already have an account?{" "}
								<MDTypography
									component={Link}
									to="/"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Sign In
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default Cover;
