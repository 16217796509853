import React, { useCallback, useEffect, useState } from "react";

// @mui material components
import {
	IconButton,
	Menu,
	MenuItem,
	Grid,
	CircularProgress,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "utilities/Footer";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";

// Material Dashboard 2 React examples
import DataTable from "utilities/Tables/DataTable";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/auth";
import { safeDateFormat } from "utils";
import {
	deleteCaseFile,
	getAllCaseFiles,
	updateCaseStatus,
} from "services/caseFileService";
import NewCaseFile from "components/create-case-file";
import GenerateReport from "components/generate-report";
import EditCaseFile from "components/edit-case-file";
import SearchBar from "components/search-bar";

const SwalDialog = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

function CaseFiles() {
	const { user } = useAuth();
	const [keyword, setKeyword] = useState("");
	const [date, setDate] = useState("");
	const [fileData, setFileData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [selectedId, setSelectedId] = useState("");
	const [selectedCase, setSelectedCase] = useState({});

	const navigate = useNavigate();

	const fetchCaseFiles = useCallback(async () => {
		setLoading(true);
		try {
			const res = await getAllCaseFiles(keyword, date);
			const { data } = res.data;
			setFileData(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	}, [keyword, date]);

	useEffect(() => {
		fetchCaseFiles();
	}, [keyword, date, fetchCaseFiles]);

	const onSearch = async (entry) => {
		setLoading(true);
		try {
			setKeyword(entry);
			const res = await getAllCaseFiles(entry, date);
			const { data } = res.data;
			setFileData(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log("Search Error", error.message);
		}
	};

	const editCaseFile = async (data) => {
		setOpenEditModal(true);
		setSelectedCase(data);
	};

	const deleteFile = async (itemId) => {
		const result = await SwalDialog.fire({
			title: "Confirm Delete",
			text: "You are about to Delete this user",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});
		if (result.isConfirmed) {
			try {
				const res = await deleteCaseFile(itemId);
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchCaseFiles();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire("Error", error.message, "error");
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "File not deleted", "info");
		}
	};
	const markAsClosed = async (itemId) => {
		const result = await SwalDialog.fire({
			title: "Confirm Close",
			text: "You are about mark this file as Closed!",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});
		if (result.isConfirmed) {
			try {
				const res = await updateCaseStatus(itemId, { status: "Closed" });
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchCaseFiles();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire("Error", error.message, "error");
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "File not closed", "info");
		}
	};
	const markAsOpen = async (itemId) => {
		const result = await SwalDialog.fire({
			title: "Confirm Open",
			text: "You are about mark this file as Open!",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});
		if (result.isConfirmed) {
			try {
				const res = await updateCaseStatus(itemId, { status: "Open" });
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchCaseFiles();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire("Error", error.message, "error");
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "File not Opened", "info");
		}
	};

	const DropdownMenu = ({ fileId, data }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleEdit = () => {
			editCaseFile(data);
			handleCloseMenu();
		};

		const handleDelete = () => {
			deleteFile(fileId);
			handleCloseMenu();
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem onClick={() => navigate(`/case-file-view/${fileId}`)}>
						View
					</MenuItem>

					{[
						"Admin",
						"Super-Admin",
						"State-Manager",
						"Tech",
						"Group-Head",
						"Coordinator",
					].includes(user.role) ? (
						<MenuItem onClick={handleEdit}>Edit</MenuItem>
					) : null}
					{["Admin", "Super-Admin", "Tech"].includes(user.role) ? (
						<MenuItem onClick={markAsClosed}>Close</MenuItem>
					) : null}
					{["Admin", "Super-Admin", "Tech"].includes(user.role) ? (
						<MenuItem onClick={markAsOpen}>Open</MenuItem>
					) : null}
					{["Admin", "Super-Admin", "Tech"].includes(user.role) ? (
						<MenuItem onClick={handleDelete}>Remove</MenuItem>
					) : null}
				</Menu>
			</>
		);
	};

	const columns = [
		{
			Header: "RPDN Number",
			accessor: "file_id",
			width: "20%",
			align: "left",
		},
		{
			Header: "Activities",
			accessor: "total_reports",
			align: "center",
		},
		{
			Header: "Status",
			accessor: "status",
			align: "center",
		},
		{
			Header: "Date Created",
			accessor: "date_created",
			align: "center",
		},
		{
			Header: "Last Updated",
			accessor: "last_updated",
			align: "center",
		},
		{ Header: "action", accessor: "action", align: "center" },
	];

	const rows = fileData?.map((item) => {
		return {
			file_id: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{item?.rpdnId}
				</MDTypography>
			),
			total_reports: item.reports.length ? item.reports.length : 0,
			status: (
				<MDBox ml={-1}>
					<MDBadge
						badgeContent={item?.status}
						color={
							item?.status === "Open"
								? "success"
								: item?.status === "Closed"
								? "dark"
								: "error"
						}
						variant="gradient"
						size="sm"
					/>
				</MDBox>
			),
			date_created: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{safeDateFormat(new Date(item?.createdAt), "dd/MM/yyyy")}
				</MDTypography>
			),
			last_updated: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{safeDateFormat(new Date(item?.updatedAt), "dd/MM/yyyy")}
				</MDTypography>
			),
			action: <DropdownMenu fileId={item?._id} data={item} />,
		};
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<NewCaseFile
				open={open}
				handleClose={handleClose}
				updateCaseFiles={fetchCaseFiles}
			/>
			<EditCaseFile
				open={openEditModal}
				handleClose={() => setOpenEditModal(false)}
				selectedCase={selectedCase}
				updateCaseFiles={fetchCaseFiles}
			/>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" color="white">
									Case Files
								</MDTypography>

								<MDBox display="flex" justifyContent="flex-end" alignItems="center">
									<GenerateReport />
									<MDButton variant="outlined" onClick={handleClickOpen}>
										Create New File
									</MDButton>
								</MDBox>
							</MDBox>
							<MDBox pt={3}>
								{loading ? (
									<MDBox display="flex" justifyContent="center" alignItems="center">
										<MDTypography variant="h6">
											<MDBox
												display="flex"
												justifyContent="center"
												alignItems="center"
											>
												<CircularProgress color="info" />
											</MDBox>{" "}
											Loading...
										</MDTypography>
									</MDBox>
								) : null}
								{rows?.length === 0 ? (
									<MDBox display="flex" justifyContent="center" alignItems="center">
										<MDTypography variant="h6">No Records Found</MDTypography>
									</MDBox>
								) : (
									<>
										<MDBox px={2} display="flex" justifyContent="space-between">
											<div></div>
											<SearchBar
												onSearch={onSearch}
												searchTerm={keyword}
												setSearchTerm={setKeyword}
											/>
										</MDBox>
										<MDBox>
											<DataTable
												table={{ columns, rows }}
												isSorted={false}
												entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
												showTotalEntries={true}
												noEndBorder
											/>
										</MDBox>
									</>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default CaseFiles;
