import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import MDBox from "components/MDBox";

export default function SearchBar({onSearch, searchTerm, setSearchTerm }) {
  
	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleSearchClick = () => {
		if (searchTerm) {
			onSearch(searchTerm);
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleSearchClick();
		}
	};

	return (
		<MDBox mx={2} mt={2} py={3} px={2}>
			<div>
				<TextField
					id="outlined-search"
					label="Search"
					type="search"
					variant="outlined"
					value={searchTerm}
					onChange={handleSearchChange}
					onKeyPress={handleKeyPress}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end" onClick={handleSearchClick}>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
		</MDBox>
	);
}
