// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
	const { href, name } = company;
	const { size } = typography;

	const renderLinks = () =>
		links.map((link) => (
			<MDBox key={link.name} component="li"  px={2} lineHeight={1}>
				<Link href={link.href} target="_blank">
					<MDTypography variant="button" fontWeight="regular" color="text">
						{link.name}
					</MDTypography>
				</Link>
			</MDBox>
		));

	return (
		<MDBox
			sx={{
				marginTop: "calc(20% + 60px)",
				position: "sticky",
				bottom: 0,
			}}
			component="footer"
		>
			<MDBox
				width="100%"
				display="flex"
				flexDirection={{ xs: "column", lg: "row" }}
				justifyContent="space-evenly"
				alignItems="center"
				px={1.5}
			>
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexWrap="wrap"
					color="text"
					fontSize={size.sm}
					px={1.5}
				>
					&copy; {new Date().getFullYear()},
					<Link href={href} target="_blank">
						<MDTypography variant="button" fontWeight="medium">
							&nbsp;{name}&nbsp;
						</MDTypography>
					</Link>
					for better Governance
				</MDBox>
				<MDBox
					component="ul"
					sx={({ breakpoints }) => ({
						display: "flex",
						flexWrap: "wrap",
						alignItems: "center",
						justifyContent: "center",
						listStyle: "none",
						mt: 3,
						mb: 0,
						p: 0,

						[breakpoints.up("lg")]: {
							mt: 0,
						},
					})}
				>
					{renderLinks()}
				</MDBox>
			</MDBox>
		</MDBox>
	);
}

// Setting default values for the props of Footer
Footer.defaultProps = {
	company: { href: "https://www.ppdc.org/", name: "PPDC" },
	links: [
		{ href: "https://www.ppdc.org/about-us/", name: "About Us" },
		{ href: "https://www.ppdc.org/resources/our-reports/", name: "Reports" },
	],
};

// Typechecking props for the Footer
Footer.propTypes = {
	company: PropTypes.objectOf(PropTypes.string),
	links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
