import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { toast } from "react-toastify";
import { updateUserData } from "services/memberService";
import { myProfile } from "services/userService";
import Signature from "../Signature";

function PersonalDetails() {
	const genderData = [
		{ id: "1", name: "Male", value: "Male" },
		{ id: "2", name: "Female", value: "Female" },
		{ id: "3", name: "Other", value: "Other" },
	];
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [selectedGender, setSelectedGender] = useState(null);

	const handleGenderChange = (event, newValue) => {
		setSelectedGender(newValue);
	};

	const fetchMyData = async () => {
		try {
			const res = await myProfile();
			const { data } = res;
			setName(data.name);
			setEmail(data.email);
			setPhone(data.phone);
			setSelectedGender(genderData.find((g) => g.value === data.gender));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchMyData();
	}, []);

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();
		if (!name) {
			toast("Please don't submit an empty field", {
				type: "error",
				autoClose: 3000,
			});
			setIsLoading(false);
			return;
		}

		const formData = new FormData();
		formData.append("name", name);
		formData.append("phone", phone);
		formData.append("email", email);

		try {
			const res = await updateUserData(formData);
			const { message, status } = res;
			if (status === "success") {
				toast(message, { type: "success", autoClose: 3000 });
				fetchMyData();
				setIsLoading(false);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setIsLoading(false);
			}
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<>
			<MDBox p={2}>
				<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
					Edit Personal Details
				</MDTypography>
			</MDBox>
			<MDBox px={2}>
				<MDBox mb={2}>
					<MDInput
						type="text"
						variant="standard"
						label="Full Name"
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</MDBox>
				<MDBox mb={2}>
					<MDInput
						type="email"
						variant="standard"
						label="Email"
						fullWidth
						value={email}
						readOnly
					/>
				</MDBox>
				<MDBox mb={2}>
					<MDInput
						type="text"
						variant="standard"
						label="Phone Number"
						fullWidth
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</MDBox>
				<div>
					<Autocomplete
						value={selectedGender}
						onChange={handleGenderChange}
						options={genderData || []}
						getOptionLabel={(option) => (option.name ? option.name : option)}
						renderInput={(params) => (
							<MDInput
								{...params}
								type="text"
								label="Select Gender"
								variant="standard"
								fullWidth
							/>
						)}
					/>
				</div>
				<MDBox mt={2} mb={2}>
					<Signature />
				</MDBox>
				<MDBox mt={3}>
					<MDButton
						type="submit"
						variant="gradient"
						color="info"
						disabled={isLoading}
						onClick={handleFormSubmit}
					>
						{isLoading ? "Loading..." : "Update"}
					</MDButton>
				</MDBox>
			</MDBox>
		</>
	);
}

export default PersonalDetails;
