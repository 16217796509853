import { useState, useEffect, useCallback } from "react";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/lab/Autocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { addMemberToGroup } from "../../services/groupService";
import { getClinicGroups } from "services/clinicService";

function ChangeMemberGroup(props) {
	const { handleClose, open, clinic, updateData, memberData } = props;
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [clinicGroups, setClinicGroups] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchClinicGroups = useCallback(async () => {
		try {
			const res = await getClinicGroups(clinic?._id);
			const { data } = res.data;
			setClinicGroups(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	}, [clinic?._id]);

	useEffect(() => {
		if (open) {
			fetchClinicGroups();
		}
	}, [open, clinic?._id, fetchClinicGroups]);

	const handleGroupSelected = (event, newValue) => {
		setSelectedGroup(newValue);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		if (!selectedGroup) {
			toast("Please select group to add the member", {
				type: "error",
				autoClose: 7000,
			});
			return;
		}

		try {
			setIsLoading(true);
			const res = await addMemberToGroup(selectedGroup?._id, {
				members: [memberData?._id],
			});
			const { message, status } = res;
			toast(message, { type: status, autoClose: 3000 });
			updateData();
			handleClose();
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		} finally {
			setIsLoading(false);
			setSelectedGroup(null);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Change {memberData?.name} from group: {clinic?.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<MDBox pt={2} px={2}>
						<Autocomplete
							value={selectedGroup}
							onChange={handleGroupSelected}
							options={clinicGroups || []}
							getOptionLabel={(option) => (option.name ? option.name : "")}
							renderInput={(params) => (
								<MDInput
									{...params}
									type="text"
									label="Group"
									variant="standard"
									fullWidth
								/>
							)}
						/>
						<MDBox
							mt={4}
							mb={3}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<MDButton variant="gradient" color="dark" onClick={handleClose}>
								Close
							</MDButton>{" "}
							&nbsp; &nbsp;
							<MDButton
								type="submit"
								variant="gradient"
								color="info"
								disabled={isLoading}
								onClick={handleFormSubmit}
							>
								{isLoading ? "Loading..." : "Save"}
							</MDButton>
						</MDBox>
					</MDBox>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<MDBox px={3} pb={3}>
					<MDButton variant="outlined" onClick={handleClose} fullWidth>
						Cancel
					</MDButton>
				</MDBox>
			</DialogActions>
		</Dialog>
	);
}

export default ChangeMemberGroup;
