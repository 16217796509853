import httpService from "./httpService";

export const addGroup = async (id, form) => {
	try {
		const response = await httpService.post(`/group/create/${id}`, form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllGroups = async () => {
	try {
		const res = await httpService.get("/group/fetch/all");
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getClinicGroups = async () => {
	try {
		const res = await httpService.get("/group/my/all");
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewGroup = async (id) => {
	try {
		const res = await httpService.get(`/group/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteGroup = async (id) => {
	try {
		const res = await httpService.delete(`/group/${id}/delete`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateGroup = async (id, form) => {
	try {
		const res = await httpService.put(`/group/${id}/update`, form);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const addMemberToGroup = async (id, payload) => {
	try {
		const res = await httpService.put(`/group/${id}/add-members`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const removeMemberFromGroup = async (id, payload) => {
	try {
		const res = await httpService.put(`/group/${id}/remove-members`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
