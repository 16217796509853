import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useParams } from "react-router-dom";
import { viewUserProfile } from "services/userService";

function PersonalDetails() {
	const { userId } = useParams();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState("");
	const [PWD, setPWD] = useState("");

	const [userSignature, setUserSignature] = useState("");

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const res = await viewUserProfile(userId);
				const { data } = res;
				setName(data.name || "");
				setEmail(data.email || "");
				setPhone(data.phone || "");
				setUserSignature(data.signature?.url || "");
				setGender(`Gender: ${data.gender ? data.gender : "Not Specified"}`);
				setPWD(`PWD: ${data.pwd ? data.pwd : "Not Specified"}`);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUserData();
	}, [userId]);

	return (
		<>
			<MDBox p={2}>
				<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
					Member Details
				</MDTypography>
			</MDBox>
			<MDBox pt={2} px={2}>
				<MDBox mb={2}>
					<MDInput type="text" variant="standard" fullWidth value={name} readOnly />
				</MDBox>
				<MDBox mb={2}>
					<MDInput
						type="email"
						variant="standard"
						fullWidth
						value={email}
						readOnly
					/>
				</MDBox>
				<MDBox mb={2}>
					<MDInput type="text" variant="standard" fullWidth value={phone} readOnly />
				</MDBox>
				<MDBox mb={2}>
					<MDInput type="text" variant="standard" fullWidth value={gender} readOnly />
				</MDBox>
				<MDBox mb={2}>
					<MDInput type="text" variant="standard" fullWidth value={PWD} readOnly />
				</MDBox>
				<MDBox mb={2}>
					{userSignature ? (
						<MDBox
							p={3}
							sx={{
								width: "100%",
								height: "100%",
								backgroundImage: `url(${userSignature})`,
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
							}}
						/>
					) : (
						<MDBox p={3} sx={{ width: "100%", height: "100%" }}>
							<MDTypography variant="body2" color="textSecondary">
								No Signature
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		</>
	);
}

export default PersonalDetails;
