import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import avatar_placeholder from "assets/images/avatar_placeholder.png";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { myProfile } from "services/userService";

function Header({ children }) {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [userName, setUserName] = useState("");
	const [gender, setGender] = useState("");

	const fetchMyData = async () => {
		try {
			const res = await myProfile();
			const { data } = res;
			setUserName(data.name);
			setGender(data.gender);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchMyData();
	}, []);

	useEffect(() => {
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		window.addEventListener("resize", handleTabsOrientation);
		handleTabsOrientation();

		return () => window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	return (
		<MDBox position="relative" mb={5}>
			<MDBox
				display="flex"
				alignItems="center"
				position="relative"
				minHeight="18.75rem"
				borderRadius="xl"
				sx={{
					backgroundImage: ({
						functions: { rgba, linearGradient },
						palette: { gradients },
					}) =>
						`${linearGradient(
							rgba(gradients.info.main, 0.6),
							rgba(gradients.info.state, 0.6),
						)}, url(${backgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
					overflow: "hidden",
				}}
			/>
			<Card sx={{ position: "relative", mt: -8, mx: 3, py: 2, px: 2 }}>
				<Grid container spacing={3} alignItems="center">
					<Grid item>
						<MDBox sx={{ position: "relative" }}>
							<MDAvatar
								src={avatar_placeholder}
								alt="profile-image"
								size="xl"
								shadow="sm"
							/>
						</MDBox>
					</Grid>
					<Grid item>
						<MDBox height="100%" mt={0.5} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								{userName}
							</MDTypography>
							<MDTypography
								component="a"
								variant="caption"
								color="text"
								fontWeight="medium"
							>
								Gender: {gender || "Not Specified"}
							</MDTypography>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}></Grid>
				</Grid>
				{children}
			</Card>
		</MDBox>
	);
}

Header.defaultProps = {
	children: "",
};

Header.propTypes = {
	children: PropTypes.node,
};

export default Header;
