// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import Header from "layouts/member/components/Header";
import Organisation from "./components/Clinic";
import PersonalDetails from "./components/PersonalDetails";

function MemberProfile() {
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox mb={2} />
			<Header>
				<MDBox mt={5} mb={3}>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} xl={6}>
							<PersonalDetails />
						</Grid>
						<Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
							<Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
							<Organisation />
							<Divider orientation="vertical" sx={{ mx: 0 }} />
						</Grid>
					</Grid>
				</MDBox>
			</Header>
			<Footer />
		</DashboardLayout>
	);
}

export default MemberProfile;
