import React, { useState } from 'react'
import html2pdf from 'html2pdf.js';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import Editor from 'components/Editor';
import MDButton from 'components/MDButton';



const CaseReviewBlank = () => {
    const [caseId, setCaseId] = useState("");
	const [note, setNote] = useState("");
	const [courtHearing, setCourtHearing] = useState({
		nameOfCourt: "",
		nameOfJudgeOrMagistrate: "",
		matterSlatedFor: "",
		wentAsSlated: "",
		reason: "",
		outcome: "",
		nextAdjournmentDate: "",
		reasonForAdjournment: "",
	});
	const [activity, setActivity] = useState({
		place: "",
		type: "",
	});
	const [externalParticipants, setExternalParticipants] = useState([]);
	const [internalParticipants, setInternalParticipants] = useState([]);
	const [nextStep, setNextStep] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");


    const handleDownloadPDF = () => {

    const element = document.getElementById('hidden-content');
    const opt = {
      margin: 1,
      filename:'case-review.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  };
  return (
    <>
     <div style={{ display: 'none' }}>
        <div id="hidden-content">
    <MDBox pt={6} pb={3}>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography
									variant="h6"
									color="white"
									fontWeight="medium"
									textTransform="capitalize"
								>
									Case Review Form
								</MDTypography>
							</MDBox>
							<MDBox pt={6} pb={3}>
								<MDBox px={2}>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={6}>
											<div>
												
														<MDInput
															type="text"
															label="Location State"
															variant="standard"
															fullWidth
														/>
													
											</div>
										</Grid>
										<Grid item xs={12} sm={6}>
											<MDInput
												type="text"
												variant="standard"
												label="Latitude"
												fullWidth
												value={latitude}
												readOnly
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<MDInput
												type="text"
												variant="standard"
												label="Longitude"
												fullWidth
												value={longitude}
												readOnly
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Case ID"
													fullWidth
													value={caseId}
													onChange={(e) => setCaseId(e.target.value)}
												/>
											</MDBox>
										</Grid>
										<Grid item xs={12} sm={6}>
											<MDBox mb={2} mt={3}>
												
														<MDInput
															type="text"
															label="Clinic Name"
															variant="standard"
															fullWidth
														/>
												
											</MDBox>
										</Grid>
									</Grid>

									{/* Activity */}
									<MDBox mb={2} mt={3}>
										<MDTypography variant="h6" fontWeight="medium" gutterBottom>
											Activity
										</MDTypography>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="Place of activity"
												fullWidth
												value={activity.place}
												onChange={(e) =>
													setActivity({ ...activity, place: e.target.value })
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="Type of activity"
												fullWidth
												value={activity.type}
												onChange={(e) => setActivity({ ...activity, type: e.target.value })}
											/>
										</MDBox>
									</MDBox>

									{/* Court Hearing Group */}
									<MDBox mb={2} mt={3}>
										<MDTypography variant="h6" fontWeight="medium" gutterBottom>
											Court Hearing
										</MDTypography>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="name of court"
												fullWidth
												value={courtHearing.nameOfCourt}
												onChange={(e) =>
													setCourtHearing({ ...courtHearing, nameOfCourt: e.target.value })
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="Name of Judge or Magistrate"
												fullWidth
												value={courtHearing.nameOfJudgeOrMagistrate}
												onChange={(e) =>
													setCourtHearing({
														...courtHearing,
														nameOfJudgeOrMagistrate: e.target.value,
													})
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="Matter Slated for"
												fullWidth
												value={courtHearing.matterSlatedFor}
												onChange={(e) =>
													setCourtHearing({
														...courtHearing,
														matterSlatedFor: e.target.value,
													})
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												type="text"
												variant="standard"
												label="Went as Slated "
												fullWidth
												value={courtHearing.wentAsSlated}
												onChange={(e) =>
													setCourtHearing({ ...courtHearing, wentAsSlated: e.target.value })
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"Reason"}
												content={courtHearing.reason}
												handleInputChange={(e) =>
													setCourtHearing({ ...courtHearing, reason: e.target.value })
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"Outcome"}
												content={courtHearing.outcome}
												handleInputChange={(e) =>
													setCourtHearing({ ...courtHearing, outcome: e.target.value })
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												type="date"
												variant="standard"
												label="Next Adjournment Date"
												fullWidth
												value={courtHearing.nextAdjournmentDate}
												onChange={(e) =>
													setCourtHearing({
														...courtHearing,
														nextAdjournmentDate: e.target.value,
													})
												}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"Reason for Adjournmnent"}
												content={courtHearing.reasonForAdjournment}
												handleInputChange={(e) =>
													setCourtHearing({
														...courtHearing,
														reasonForAdjournment: e.target.value,
													})
												}
											/>
										</MDBox>
									</MDBox>

									<MDBox mb={2}>
										<Editor
											title={"Note"}
											content={note}
											handleInputChange={(e) => setNote(e.target.value)}
										/>
									</MDBox>
									<MDBox mb={2}>
										<Editor
											title={"Next Step"}
											content={nextStep}
											handleInputChange={(e) => setNextStep(e.target.value)}
										/>
									</MDBox>
									<MDBox mb={2}>
										<Editor
											title={"External Participants"}
											content={externalParticipants}
											handleInputChange={(e) => setExternalParticipants(e.target.value)}
										/>
									</MDBox>
									<MDBox mb={2}>
										<Editor
											title={"Internal Participants"}
											content={internalParticipants}
											handleInputChange={(e) => setInternalParticipants(e.target.value)}
										/>
									</MDBox>

									
								</MDBox>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
            </div>
            </div>
            <MDButton
        type="button"
        variant="outlined"
        onClick={handleDownloadPDF}
      >
        Download Case Review Form
      </MDButton>
    </>
  )
}

export default CaseReviewBlank