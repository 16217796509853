import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getConferenceWeeklySummary, getDetaineeWeeklySummary, getReviewWeeklySummary } from "services/analyticsService";
import { Divider } from "@mui/material";
import ReportsBarChart from "utilities/Charts/BarCharts/ReportsBarChart";


function WeeklySummary({ state, year }) {
	const [conferenceData, seConferenceData] = useState({});
	const [reviewData, setReviewData] = useState({});
	const [detaineeData, setDetaineeData] = useState({});

	useEffect(() => {
		const fetchConferenceData = async () => {
			try {
				const res = await getConferenceWeeklySummary(year, state);
				const { data } = res;
				seConferenceData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchConferenceData();
	}, [year, state]);

	useEffect(() => {
		const fetchReviewData = async () => {
			try {
				const res = await getReviewWeeklySummary(year, state);
				const { data } = res;
				setReviewData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchReviewData();
	}, [year, state]);

	useEffect(() => {
		const fetchDetaineeData = async () => {
			try {
				const res = await getDetaineeWeeklySummary(year, state);
				const { data } = res;
				setDetaineeData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchDetaineeData();
	}, [year, state]);

	return (
		<MDBox mt={4.5}>
			<MDBox mx={2} mb={3} py={3} px={2}>
				<MDTypography variant="h6" color="dark">
					Weekly Report Summary
				</MDTypography>
				<Divider />
			</MDBox>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<MDBox mb={3}>
							<ReportsBarChart
								color="info"
								title="Case Conference"
								description="Case Conference Chart"
								date={year}
								chart={conferenceData}
							/>
						</MDBox>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<MDBox mb={3}>
							<ReportsBarChart
								color="success"
								title="Case Review"
								description="Case Review Chart"
								date={year}
								chart={reviewData}
							/>
						</MDBox>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<ReportsBarChart
							color="dark"
							title="Detainee Performance"
							description="Detainee Performance Chart"
							date={year}
							chart={detaineeData}
						/>
					</MDBox>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default WeeklySummary;
