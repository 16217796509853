import httpService from "./httpService";

export const addUser = async (form) => {
	try {
		const response = await httpService.post("/user/signup", form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const changeRole = async (id, payload) => {
	try {
		const res = await httpService.put(`/user/${id}/change-role`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const changeUserStatus = async (id, payload) => {
	try {
		const res = await httpService.put(`/user/${id}/update-status`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const myProfile = async () => {
	try {
		const res = await httpService.get(`/user/my-profile`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateSignature = async (payload) => {
	try {
		const res = await httpService.put(`/user/update-signature`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateProfile = async (payload) => {
	try {
		const res = await httpService.put(`/user/update-profile`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updatePhoto = async () => {
	try {
		const res = await httpService.put(`/user/update-photo`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewUserProfile = async (id) => {
	try {
		const res = await httpService.get(`/user/${id}/profile`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateUser = async (id, payload) => {
	try {
		const res = await httpService.put(`/user/${id}/update`, payload);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllUsers = async (page, limit, status, keyword) => {
	let url = `/user/all`;
	const params = new URLSearchParams();

	// Only append parameters that exist
	if (status) params.append("status", status);
	if (keyword) params.append("keyword", keyword);
	if (page) params.append("page", page);
	if (limit) params.append("limit", limit);

	// Add query params if present
	if (params.toString()) {
		url += "?" + params.toString();
	}
	try {
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteUser = async (id) => {
	try {
		const res = await httpService.delete(`/user/${id}/delete`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
