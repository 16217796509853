import axios from "axios";
import { baseURL } from "../shared/url";
import {
	getLocalAccessToken,
	getLocalRefreshToken,
	updateLocalAccessToken,
	removeToken,
	updateLocalRefreshToken,
} from "./tokenService";

let isRefreshing = false; // Flag to track token refresh process
let refreshSubscribers = []; // Array to hold pending request subscribers

const axiosInstance = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

// Function to refresh access token
const refreshAccessToken = async () => {
	try {
		isRefreshing = true;
		const refreshToken = getLocalRefreshToken();

		// Request new access token using refresh token
		const response = await axiosInstance.post("/auth/refresh/token", {
			refreshToken: refreshToken,
		});

		const { access_token, refresh_token } = response.data;
		updateLocalAccessToken(access_token);
		updateLocalRefreshToken(refresh_token);
		isRefreshing = false;

		// Resolve all pending requests with the new access token
		refreshSubscribers.forEach((subscriber) => subscriber(access_token));
		refreshSubscribers = [];
	} catch (error) {
		isRefreshing = false;
		const errorMessage = error.response
			? error.response.data.message
			: error.message;
		removeToken(); // Clear tokens in case of error
		return Promise.reject(new Error(errorMessage)); // Reject with detailed error
	}
};

axiosInstance.interceptors.request.use(
	async (config) => {
		const access_token = getLocalAccessToken();
		if (access_token !== null) {
			config.headers["Authorization"] = `Bearer ${access_token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		const authUrls = ["/auth/signin", "/auth/verify-signin"];

		// Check if it's an authentication error and not one of the auth URLs
		if (!authUrls.includes(originalRequest.url) && error.response) {
			// If 401, handle token refresh
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;

				// If no other refresh process is happening, start refreshing
				if (!isRefreshing) {
					console.log("Refreshing token...");
					try {
						await refreshAccessToken();
					} catch (refreshError) {
						console.error("Token refresh failed");
						removeToken();
						return Promise.reject(refreshError);
					}
				}
				// Retry the original request with the new access token
				const retryOriginalRequest = new Promise((resolve) => {
					refreshSubscribers.push((access_token) => {
						originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
						console.log("Retrying request with new access token");
						resolve(axiosInstance(originalRequest)); // Resend request
					});
				});

				return retryOriginalRequest;
			}
		}

		// If error status is 401 again, remove tokens and force logout
		if (error.response && error.response.status === 401) {
			console.log("401 error after token refresh. Removing token.");
			removeToken();
		}

		// Extract detailed error message for logging or handling in services
		const errorMessage = error.response
			? error.response.data.message || "An error occurred"
			: error.message;

		return Promise.reject(new Error(errorMessage)); // Reject with detailed error
	},
);

const httpService = {
	get: axiosInstance.get,
	post: (url, data, config) => {
		if (data instanceof FormData) {
			return axiosInstance.post(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.post(url, data, config);
		}
	},
	put: (url, data, config) => {
		if (data instanceof FormData) {
			return axiosInstance.put(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.put(url, data, config);
		}
	},
	delete: axiosInstance.delete,
	patch: (url, data, config) => {
		if (data instanceof FormData) {
			return axiosInstance.patch(url, data, {
				...config,
				headers: { ...config?.headers, "Content-Type": "multipart/form-data" },
			});
		} else {
			return axiosInstance.patch(url, data, config);
		}
	},
};

export default httpService;
