import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	IconButton,
	Menu,
	MenuItem,
	Grid,
	CircularProgress,
	Card,
	Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import DataTable from "utilities/Tables/DataTable";
import { viewClinic } from "services/clinicService";
import { useAuth } from "context/auth";
import EditGroup from "components/edit-group";
import NewGroup from "components/create-group";
import AddMemberToGroup from "components/member-group";
import MDButton from "components/MDButton";

const ClinicDetails = () => {
	const { user } = useAuth();
	const { id } = useParams();
	const navigate = useNavigate();
	const [clinic, setClinic] = useState(null);
	const [loading, setLoading] = useState(true);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openAddModal, setOpenAddModal] = useState(false);
	const [openNewGroupModal, setOpenNewGroupModal] = useState(false);
	const [groupData, setGroupData] = useState(null);

	const fetchClinic = useCallback(async () => {
		try {
			const response = await viewClinic(id);
			setClinic(response.data);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching Clinic:", error);
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchClinic();
	}, [id, fetchClinic]);

	const handleNewGroup = () => {
		setOpenNewGroupModal(true);
	};

	const DropdownMenu = ({ itemId, data }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleEdit = () => {
			setOpenEditModal(true);
			setGroupData(data);
		};

		const handleAddMember = () => {
			setGroupData(data);
			setOpenAddModal(true);
		};

		const handleDelete = () => {
			// deleteFile(itemId);
			handleCloseMenu();
		};

		const handleView = () => {
			navigate(`/group-details/${data._id}`);
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem onClick={handleView}>
						<IconButton>
							<Icon>visibility</Icon>
						</IconButton>
						View
					</MenuItem>

					{[
						"Admin",
						"Super-Admin",
						"State-Manager",
						"Tech",
						"Group-Head",
						"Coordinator",
					].includes(user.role) ? (
						<MenuItem onClick={handleEdit}>
							<IconButton>
								<Icon>edit</Icon>
							</IconButton>
							Edit
						</MenuItem>
					) : null}
					{[
						"Admin",
						"Super-Admin",
						"State-Manager",
						"Tech",
						"Group-Head",
						"Coordinator",
					].includes(user.role) ? (
						<MenuItem onClick={handleAddMember}>
							<IconButton>
								<Icon>person_add</Icon>
							</IconButton>
							Add Member
						</MenuItem>
					) : null}
					{["Admin", "Super-Admin", "Tech"].includes(user.role) ? (
						<MenuItem onClick={handleDelete}>
							<IconButton>
								<Icon>delete</Icon>
							</IconButton>
							Remove
						</MenuItem>
					) : null}
				</Menu>
			</>
		);
	};

	const columns = [
		{ Header: "Name", accessor: "name" },
		{ Header: "Members", accessor: "members", align: "center" },
		{ Header: "Status", accessor: "status", align: "center" },
		{ Header: "Date Created", accessor: "date_created" },
		{ Header: "action", accessor: "action", align: "center" },
	];

	const rows = clinic?.groups.map((group) => ({
		name: <MDTypography variant="body2">{group.name}</MDTypography>,
		members: (
			<MDTypography variant="body2">
				{group?.members.length > 0 ? group?.members.length : 0}
			</MDTypography>
		),
		status: (
			<MDBox ml={-1}>
				<MDBadge
					badgeContent={group?.status}
					color={
						group?.status === "Active"
							? "success"
							: group?.status === "Inactive"
							? "warning"
							: group?.status === "Suspended"
							? "dark"
							: "error"
					}
					variant="gradient"
					size="sm"
				/>
			</MDBox>
		),
		date_created: (
			<MDTypography variant="body2">
				{new Date(group?.createdAt)?.toLocaleDateString()}
			</MDTypography>
		),
		action: <DropdownMenu fileId={group?._id} data={group} />,
	}));

	if (loading) {
		return (
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
			>
				<CircularProgress color="info" />
			</MDBox>
		);
	}

	if (!clinic) {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="100vh"
				>
					<MDTypography variant="h5">No data yet</MDTypography>
				</MDBox>
				<Footer />
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox p={3}>
				<Card>
					<MDBox py={3} px={2} borderRadius="lg" bgColor="dark">
						<MDTypography variant="h6" color="white" gutterBottom>
							Clinic Name: {clinic.name}
						</MDTypography>
						<MDTypography variant="h6" color="white" gutterBottom>
							Location: {clinic.location.state}
						</MDTypography>
					</MDBox>
				</Card>
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography variant="h6" color="white">
										Groups
									</MDTypography>
									{[
										"Admin",
										"Super-Admin",
										"State-Manager",
										"Tech",
										"Coordinator",
									].includes(user.role) ? (
										<MDButton variant="outlined" onClick={handleNewGroup}>
											Add New Group
										</MDButton>
									) : null}
								</MDBox>
								<MDBox pt={3}>
									{clinic?.groups?.length > 0 ? (
										<DataTable
											table={{ columns: columns, rows: rows }}
											isSorted={false}
											entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
											showTotalEntries={true}
											noEndBorder
										/>
									) : (
										<MDBox pb={1}>
											<MDTypography flex="1" mx={4} color="text" align="center">
												No groups yet
											</MDTypography>
										</MDBox>
									)}
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<EditGroup
				open={openEditModal}
				handleClose={() => setOpenEditModal(false)}
				groupData={groupData}
				updateData={fetchClinic}
			/>
			<AddMemberToGroup
				open={openAddModal}
				handleClose={() => setOpenAddModal(false)}
				groupData={groupData}
				clinic={clinic}
				updateData={fetchClinic}
			/>
			<NewGroup
				open={openNewGroupModal}
				handleClose={() => setOpenNewGroupModal(false)}
				groupData={groupData}
				clinicData={clinic}
				updateData={fetchClinic}
			/>
			<Footer />
		</DashboardLayout>
	);
};

export default ClinicDetails;
