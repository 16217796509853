import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/lab/Autocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { getAllClinics } from "../../services/clinicService";
import { addUser } from "services/userService";

function NewMember(props) {
	const { handleClose, open, updateMembers } = props;
	const [selectedClinic, setSelectedClinic] = useState(null);
	const [clinics, setClinics] = useState([]);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState("");
	const [PWD, setPWD] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const pwdData = [
		{ id: "1", name: "Yes", value: "Yes" },
		{ id: "2", name: "No", value: "No" },
		{ id: "3", name: "No_say", value: "No_say" },
	];

	const genderData = [
		{ id: "1", name: "Male", value: "Male" },
		{ id: "2 ", name: "Female", value: "Female" },
		{ id: "3", name: "Other", value: "Other" },
	];

	const fetchClinics = async () => {
		try {
			const res = await getAllClinics();
			const { data } = res.data;
			setClinics(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	useEffect(() => {
		if (open) {
			fetchClinics();
		}
	}, [open]);

	const handleClinicChange = (event, newValue) => {
		setSelectedClinic(newValue);
	};
	const handleGenderChange = (event, newValue) => {
		setGender(newValue);
	};
	const handlePWDChange = (event, newValue) => {
		setPWD(newValue);
	}

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		if (!name || !email || !phone || !setGender || !setPWD) {
			toast("Please fill all fields", { type: "error", autoClose: 3000 });
			setIsLoading(false);
			return;
		}

		if (!selectedClinic) {
			toast(
				"Please select a clinic, if you don't see a clinic, add one before you add a member",
				{ type: "error", autoClose: 7000 },
			);
			setIsLoading(false);
			return;
		}

		try {
			const res = await addUser({
				name,
				phone,
				email,
				clinic: selectedClinic._id,
				gender: setGender.value,
				PWD: setPWD.value,
			});
			const { message, status } = res;
			toast(message, { type: status, autoClose: 3000 });
			updateMembers("");
			handleClose();
			setIsLoading(false);
			setName("");
			setEmail("");
			setPhone("");
			setSelectedClinic(null);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Add Member</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div>
						<MDBox pt={2} px={2}>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Full Name"
									variant="standard"
									fullWidth
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Phone"
									variant="standard"
									fullWidth
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="email"
									label="Email"
									variant="standard"
									fullWidth
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<Autocomplete
									value={selectedClinic}
									onChange={handleClinicChange}
									options={clinics || []}
									getOptionLabel={(option) => (option.name ? option.name : option)}
									renderInput={(params) => (
										<MDInput
											{...params}
											type="text"
											label="Clinic"
											variant="standard"
											fullWidth
										/>
									)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<Autocomplete
									value={gender}
									onChange={handleGenderChange}
									options={genderData || []}
									getOptionLabel={(option) => (option.name ? option.name : option)}
									renderInput={(params) => (
										<MDInput
											{...params}
											type="text"
											label="Gender"
											variant="standard"
											fullWidth
										/>
									)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<Autocomplete
									value={PWD}
									onChange={handlePWDChange}
									options={pwdData || []}
									getOptionLabel={(option) => (option.name ? option.name : option)}
									renderInput={(params) => (
										<MDInput
											{...params}
											type="text"
											label="PWD"
											variant="standard"
											fullWidth
										/>
									)}
								/>
							</MDBox>
							<MDBox
								mt={4}
								mb={3}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<MDButton variant="gradient" color="dark" onClick={handleClose}>
									Close
								</MDButton>{" "}
								&nbsp; &nbsp;
								<MDButton
									type="submit"
									variant="gradient"
									color="info"
									disabled={isLoading}
									onClick={handleFormSubmit}
								>
									{isLoading ? "Loading..." : "Add Member"}
								</MDButton>
							</MDBox>
						</MDBox>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default NewMember;
