import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MDTextarea from "components/MDTextarea";
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

const EditorContainer = styled(Paper)({
    padding: "20px",
    marginBottom: "20px",
    // reduce the elevation for better contrast
    boxShadow: "0px 0px 2.5px 0px rgba(0, 0, 0, 0.2)",
});

const Editor = ({ title, content, handleInputChange, placeholder }) => {
    const { size } = typography;

    return (
        <EditorContainer elevation={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MDBox flexWrap="wrap" color="text" fontSize={size.sm}>
                        {title}
                    </MDBox>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <MDTextarea
                            placeholder={
                                placeholder
                                    ? placeholder
                                    : "Write something here..."
                            }
                            value={content}
                            onChange={handleInputChange}
                            rowsMin={5}
                            error={false}
                            success={false}
                            disabled={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </EditorContainer>
    );
};

export default Editor;
