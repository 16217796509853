import httpService from "./httpService";

export const createCaseConference = async (form) => {
	try {
		const response = await httpService.post(`/case-conference-form/create`, form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllCaseConferenceReport = async (
	page,
	limit,
	keyword,
	query,
) => {
	let url = `/case-conference-form/reports`;
	const year = query || new Date().getFullYear();
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllMyCaseConferenceReport = async (
	page,
	limit,
	keyword,
	query,
) => {
	const year = query || new Date().getFullYear();
	let url = `/case-conference-form/my/reports`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (query) {
			params.push(`year=${encodeURIComponent(year)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteCaseConferenceReport = async (id) => {
	try {
		const response = await httpService.delete(
			`/case-conference-form/${id}/delete`,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateCaseConferenceReport = async (id, payload) => {
	try {
		const response = await httpService.put(
			`/case-conference-form/${id}/update`,
			payload,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const addRemarkConferenceReport = async (id, payload) => {
	try {
		const response = await httpService.patch(
			`/case-conference-form/${id}/remark`,
			payload,
		);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewCaseConferenceReport = async (id) => {
	try {
		const response = await httpService.get(`/case-conference-form/${id}/view`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
