import { useState, useEffect } from "react";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card } from "@mui/material";
import { toast } from "react-toastify";
import { getAllGroups } from "services/groupService";
import { getAllUsers } from "services/userService";
import { createCaseUpload } from "services/caseUploadService";
import { useNavigate } from "react-router-dom";

function CaseUploadForm() {
    const [date, setDate] = useState(Date.now());
    const [nameOfReporter, setNameOfReporter] = useState("");
    const [group, setGroup] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [clientName, setClientName] = useState("");
    const [custodialCenterNumber, setCustodialCenterNumber] = useState("");
    const [caseId, setCaseId] = useState("");
    const [rpdnId, setRpdnId] = useState("");
    const [activity, setActivity] = useState("");
    const [update, setUpdate] = useState("");
    const [note, setNote] = useState("");
    const [nextSteps, setNextSteps] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedReporter, setSelectedReporter] = useState(null);

    const navigate = useNavigate();

    const fetchUsers = async () => {
        try {
            const res = await getAllUsers();
            const { data } = res.data;
            setUsers(data);
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        }
    };

    const fetchGroups = async () => {
        try {
            const res = await getAllGroups();
            const { data } = res.data;
            setGroups(data);
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchGroups();
    }, []);

    const handleGroupChange = (event, newValue) => {
        setSelectedGroup(newValue);
    };

    const handleReporterChange = (event, newValue) => {
        setSelectedReporter(newValue);
    };

    const handleFormClear = () => {
        // Reset form
        setDate(Date.now());
        setSelectedReporter(null);
        setSelectedGroup(null);
        setPhone("");
        setEmail("");
        setClientName("");
        setCustodialCenterNumber("");
        setCaseId("");
        setRpdnId("");
        setActivity("");
        setUpdate("");
        setNote("");
        setNextSteps("");
    };

    const handleCancel = () => {
        handleFormClear();
        window.localStorage.setItem("currentTabIndex", 2);
        navigate(-1);
    };

    const handleFormSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        const formData = {
            date,
            rpdnId,
            nameOfReporter: selectedReporter?._id,
            group: selectedGroup?._id,
            phone,
            email,
            clientName,
            custodialCenterNumber,
            caseId,
            activity,
            update,
            note,
            nextSteps,
        };

        try {
            const res = await createCaseUpload(formData);
            const { message, status } = res;
            if (status === "success") {
                toast(message, { type: "success", autoClose: 3000 });
                handleFormClear();
                setIsLoading(false);
            } else {
                toast(message, { type: "error", autoClose: 3000 });
            }
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                >
                                    Case Upload Form
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={6} pb={3} px={2}>
                                <MDBox px={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="date"
                                                variant="standard"
                                                label="Date"
                                                fullWidth
                                                value={date}
                                                onChange={(e) =>
                                                    setDate(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="RPDN ID"
                                                fullWidth
                                                value={rpdnId}
                                                onChange={(e) =>
                                                    setRpdnId(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                value={selectedReporter}
                                                onChange={handleReporterChange}
                                                options={users || []}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                        ? option.name
                                                        : ""
                                                }
                                                renderInput={(params) => (
                                                    <MDInput
                                                        {...params}
                                                        type="text"
                                                        label="Name of Reporter"
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                value={selectedGroup}
                                                onChange={handleGroupChange}
                                                options={groups || []}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                        ? option.name
                                                        : ""
                                                }
                                                renderInput={(params) => (
                                                    <MDInput
                                                        {...params}
                                                        type="text"
                                                        label="Group"
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Phone"
                                                fullWidth
                                                value={phone}
                                                onChange={(e) =>
                                                    setPhone(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="email"
                                                variant="standard"
                                                label="Email"
                                                fullWidth
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Client Name"
                                                fullWidth
                                                value={clientName}
                                                onChange={(e) =>
                                                    setClientName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Custodial Center Number"
                                                fullWidth
                                                value={custodialCenterNumber}
                                                onChange={(e) =>
                                                    setCustodialCenterNumber(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Case ID"
                                                fullWidth
                                                value={caseId}
                                                onChange={(e) =>
                                                    setCaseId(e.target.value)
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Activity"
                                                fullWidth
                                                value={activity}
                                                onChange={(e) =>
                                                    setActivity(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Update"
                                                fullWidth
                                                value={update}
                                                onChange={(e) =>
                                                    setUpdate(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Note"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={note}
                                                onChange={(e) =>
                                                    setNote(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Next Steps"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={nextSteps}
                                                onChange={(e) =>
                                                    setNextSteps(e.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        pt={4}
                                    >
                                        <Grid item>
                                            <MDButton
                                                variant="contained"
                                                color="info"
                                                onClick={handleFormSubmit}
                                                disabled={isLoading}
                                            >
                                                {isLoading
                                                    ? "Submitting..."
                                                    : "Submit"}
                                            </MDButton>
                                        </Grid>
                                        <Grid item>
                                            <MDButton
                                                variant="contained"
                                                color="warning"
                                                onClick={handleFormClear}
                                            >
                                                Clear
                                            </MDButton>
                                        </Grid>
                                        <Grid item>
                                            <MDButton
                                                variant="contained"
                                                color="error"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </MDButton>
                                        </Grid>
                                        
                                    </Grid>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CaseUploadForm;
