import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	IconButton,
	Menu,
	MenuItem,
	Grid,
	CircularProgress,
	Card,
	Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import DataTable from "utilities/Tables/DataTable";
import { useAuth } from "context/auth";
import { removeMemberFromGroup, viewGroup } from "services/groupService";
import { toast } from "react-toastify";
import ChangeMemberGroup from "components/member-change-group";


const GroupDetails = () => {
	const { user } = useAuth();
	const { id } = useParams();
	const navigate = useNavigate();
	const [group, setGroup] = useState(null);
	const [openGroupModal, setOpenGroupModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState({})
	const [loading, setLoading] = useState(true);

	const fetchGroup = useCallback(async () => {
		try {
			const response = await viewGroup(id);
			setGroup(response.data);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching group:", error);
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchGroup();
	}, [id, fetchGroup]);

	const removeHandler = async (userId) => {
		try {
			const response = await removeMemberFromGroup(id, {
				members: [userId],
			});
			const { message } = response;
			toast(message, {
				type: "success",
				autoClose: 3000,
			});
			fetchGroup();
		} catch (error) {
			console.error("Error removing member:", error);
		}
	};

	const changeUserGroup = async (userData) => {
		setOpenGroupModal(true);
		setSelectedUser(userData);
	};

	const DropdownMenu = ({ itemId, data }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleRemoveMember = () => {
			removeHandler(itemId);
		};

		const handleGroupChange = () => {
			changeUserGroup(data);
			handleCloseMenu();
		};

		const handleView = () => {
			navigate(`/dashboard/user/${data._id}`);
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem onClick={handleView}>
						<IconButton>
							<Icon>visibility</Icon>
						</IconButton>
						View Profile
					</MenuItem>
					{[
						"Admin",
						"Super-Admin",
						"State-Manager",
						"Tech",
						"Group-Head",
						"Coordinator",
					].includes(user.role) ? (
						<MenuItem onClick={handleRemoveMember}>
							<IconButton>
								<Icon>person_remove</Icon>
							</IconButton>
							Remove Member
						</MenuItem>
					) : null}
					{["Admin", "Super-Admin", "State-Manager", "Coordinator", "Tech"].includes(
						user?.role,
					) && (
						<MenuItem onClick={() => handleGroupChange()}>
							<IconButton>
								<Icon>swap_horiz</Icon>
							</IconButton>
							Change Group
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const columns = [
		{ Header: "Name", accessor: "name" },
		{ Header: "Email", accessor: "email", align: "center" },
		{ Header: "Status", accessor: "status", align: "center" },
		{ Header: "Date Joined", accessor: "date_joined" },
		{ Header: "action", accessor: "action", align: "center" },
	];

	const rows = group?.members.map((mem) => ({
		name: <MDTypography variant="body2">{mem.name}</MDTypography>,
		email: <MDTypography variant="body2">{mem.email}</MDTypography>,
		status: (
			<MDBox ml={-1}>
				<MDBadge
					badgeContent={mem?.status}
					color={
						mem?.status === "Active"
							? "success"
							: mem?.status === "Inactive"
							? "warning"
							: mem?.status === "Suspended"
							? "dark"
							: "error"
					}
					variant="gradient"
					size="sm"
				/>
			</MDBox>
		),
		date_joined: (
			<MDTypography variant="body2">
				{new Date(mem?.createdAt)?.toLocaleDateString()}
			</MDTypography>
		),
		action: <DropdownMenu itemId={mem?._id} data={mem} />,
	}));

	if (loading) {
		return (
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
			>
				<CircularProgress color="info" />
			</MDBox>
		);
	}

	if (!group) {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="100vh"
				>
					<MDBox pb={1}>
						<MDTypography flex="1" mx={4} color="text" align="center">
							No data available
						</MDTypography>
					</MDBox>
				</MDBox>
				<Footer />
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<ChangeMemberGroup
				open={openGroupModal}
				handleClose={() => setOpenGroupModal(false)}
				memberData={selectedUser}
				clinic={group?.clinic}
				updateData={fetchGroup}
			/>
			<DashboardNavbar />
			<MDBox p={3}>
				<Card>
					<MDBox py={3} px={2} borderRadius="lg" bgColor="dark">
						<MDTypography variant="h6" color="white" gutterBottom>
							Group Name: {group.name}
						</MDTypography>
						<MDTypography variant="h6" color="white" gutterBottom>
							Clinic Coordinator:{" "}
							{group.members.filter((mem) => mem.role === "Coordinator").length > 0
								? group.members
										.filter((mem) => mem.role === "Coordinator")
										.map((mem) => mem.name)
										.join(", ")
								: "None"}
						</MDTypography>
						<MDTypography variant="h6" color="white" gutterBottom>
							Total Members: {group.members.length > 0 ? group.members.length : 0}
						</MDTypography>
						<MDTypography variant="h6" color="white" gutterBottom>
							Total Report: {"10"}
						</MDTypography>
					</MDBox>
				</Card>
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography variant="h6" color="white">
										Members
									</MDTypography>
								</MDBox>
								<MDBox pt={3}>
									{loading ? (
										<MDBox display="flex" justifyContent="center" alignItems="center">
											<MDTypography variant="h6">
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													minHeight="100vh"
												>
													<CircularProgress color="info" />
												</MDBox>{" "}
												Loading...
											</MDTypography>
										</MDBox>
									) : null}
									{group.members.length === 0 ? (
										<MDBox pb={1}>
											<MDTypography flex="1" mx={4} color="text" align="center">
												No members yet
											</MDTypography>
										</MDBox>
									) : (
										<DataTable
											table={{ columns: columns, rows: rows }}
											isSorted={false}
											entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
											showTotalEntries={true}
											noEndBorder
											canSearch={true}
										/>
									)}
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
};

export default GroupDetails;
