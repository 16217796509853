import { useEffect, useState } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import CaseConference from "./case-conference";
import CaseReview from "./case-review";
import Detainee from "./detainee";
import CaseUpload from "./case-upload";
import { useAuth } from "context/auth";
import MDTypography from "components/MDTypography";

function Report() {
    const [tabIndex, setTabIndex] = useState(0);
    const { user } = useAuth();

    useEffect(() => {
        setTabIndex(
            Number(window.localStorage.getItem("currentTabIndex")) || 0
        );
    }, []);

    console.log(window.localStorage.getItem("currentTabIndex"));

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        window.localStorage.setItem("currentTabIndex", newValue);
    };

    const tabConfig = [
        {
            label: "Client Intake",
            roles: ["Clinician", "Tech"],
            component: <Detainee />,
        },
        {
            label: "Case Conference",
            roles: ["Clinician", "Lawyer", "Tech"],
            component: <CaseConference />,
        },
        {
            label: "Case Update",
            roles: ["Clinician", "Lawyer", "Tech"],
            component: <CaseUpload />,
        },
        {
            label: "Case Review",
            roles: ["Clinician", "Tech"],
            component: <CaseReview />,
        },
    ];

    const availableTabs = tabConfig.filter((tab) =>
        tab.roles.includes(user.role)
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <MDBox pb={3}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            backgroundColor: "#dee2e6",
                            borderRadius: "4px",
                            "& .MuiTabs-indicator": {
                                backgroundColor: "#1A73E8",
                            },
                            "& .MuiTab-root": {
                                color: "#f0f2f5",
                                "&.Mui-selected": {
                                    color: "#f0f2f5 !important",
                                },
                            },
                        }}
                    >
                        {availableTabs.map(({ label }, index) => (
                            <Tab key={label} label={label} />
                        ))}
                    </Tabs>
                </MDBox>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <MDBox pt={3}>
                            {availableTabs.map(
                                ({ component }, index) =>
                                    tabIndex === index && component
                            )}

                            {!availableTabs.length && (
                                <MDBox px={3} py={2}>
                                    <MDTypography variant="h6" color="text">
                                        You do not have access to create
                                        reports.
                                    </MDTypography>
                                </MDBox>
                            )}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Report;
