import { useMemo } from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ icon, title, description, height, chart, date }) {
	const data = useMemo(
		() => ({
			labels: chart.labels || [],
			datasets: chart.datasets || [],
		}),
		[chart],
	);

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
				},
				tooltip: {
					callbacks: {
						label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
					},
				},
			},
		}),
		[],
	);

	return (
		<Card>
			<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
				{title || description ? (
					<MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
						{icon.component && (
							<MDBox
								width="4rem"
								height="4rem"
								bgColor={icon.color || "dark"}
								variant="gradient"
								coloredShadow={icon.color || "dark"}
								borderRadius="xl"
								display="flex"
								justifyContent="center"
								alignItems="center"
								color="white"
								mt={-5}
								mr={2}
							>
								<Icon fontSize="medium">{icon.component}</Icon>
							</MDBox>
						)}
						<MDBox mt={icon.component ? -2 : 0}>
							{title && <MDTypography variant="h6">{title}</MDTypography>}
							<MDBox mb={2}>
								<MDTypography component="div" variant="button" color="text">
									{description}
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
				) : null}
				<MDBox height={height}>
					<Pie data={data} options={options} redraw />
				</MDBox>
				<Divider />
				<MDBox display="flex" alignItems="center">
					<MDTypography
						variant="button"
						color="text"
						lineHeight={1}
						sx={{ mt: 0.15, mr: 0.5 }}
					>
						<Icon>schedule</Icon>
					</MDTypography>
					<MDTypography variant="button" color="text" fontWeight="light">
						Year: {date}
					</MDTypography>
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Setting default values for the props of PieChart
PieChart.defaultProps = {
	icon: { color: "info", component: "" },
	title: "",
	description: "",
	height: "19.125rem",
};

// Typechecking props for the PieChart
PieChart.propTypes = {
	icon: PropTypes.shape({
		color: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"light",
			"dark",
		]),
		component: PropTypes.node,
	}),
	title: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	chart: PropTypes.objectOf(
		PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	).isRequired,
};

export default PieChart;
