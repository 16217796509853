import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useParams } from "react-router-dom";
import { viewUserProfile } from "services/userService";

function Organisation(props) {
	const [name, setName] = useState("");
	const [location, setLocation] = useState("");
	const { userId } = useParams();

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const res = await viewUserProfile(userId);
				const { data } = res;
				setName(data.clinic.name);
				setLocation(data.clinic.location.state);
			} catch (error) {
				console.log(error);
			}
		};
		fetchUserData();
	}, []);

	return (
		<>
			<MDBox px={2} sx={{ flex: 1 }}>
				<MDBox p={2}>
					<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
						Clinic Information
					</MDTypography>
				</MDBox>
				<MDBox px={2} pt={2} sx={{ flex: 1 }}>
					<MDBox mb={2}>
						<MDInput
							type="text"
							variant="standard"
							value={name}
							label="Clinic Name"
							disabled={true}
							fullWidth
						/>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							value={location}
							label="Location"
							disabled={true}
							variant="standard"
							fullWidth
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
}

export default Organisation;
