export const GOOGLE_MAPS_API_KEY = "AIzaSyAaSHVe8kf-EfQuKd-8GX4nIIW-LMpAbEM";

export const loadGoogleMapsScript = () => {
	return new Promise((resolve, reject) => {
		const existingScript = document.getElementById("googleMapsScript");

		if (!existingScript) {
			const googleMapsScript = document.createElement("script");
			googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
			googleMapsScript.id = "googleMapsScript";
			googleMapsScript.async = true;
			googleMapsScript.defer = true;

			googleMapsScript.onload = () => {
				resolve();
			};

			googleMapsScript.onerror = (error) => {
				reject(error);
			};

			window.document.body.appendChild(googleMapsScript);
		} else {
			resolve();
		}
	});
};

