import { useState, useEffect, useCallback } from "react";
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon"; 
import MDBox from "components/MDBox";
import Sidenav from "utilities/Sidenav";
import Configurator from "utilities/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { routes, sideBarData } from "routes";
import {
	useAppContextController,
	setMiniSidenav,
	setOpenConfigurator,
} from "context";
import { useAuth } from "context/auth";
import { removeToken } from "services/tokenService";
import { loadGoogleMapsScript } from "services/geoService";

export default function App() {
	const { dispatch: authDispatch } = useAuth();
	const [controller, appDispatch] = useAppContextController();
	const {
		miniSidenav,
		direction,
		layout,
		openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(appDispatch, false);
			setOnMouseEnter(true);
		}
	};

	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(appDispatch, true);
			setOnMouseEnter(false);
		}
	};

	const handleConfiguratorOpen = () =>
		setOpenConfigurator(appDispatch, !openConfigurator);

	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	useEffect(() => {
		loadGoogleMapsScript()
			.then(() => {
				console.log("Google Maps API loaded");
			})
			.catch((error) => {
				console.error("Google Maps API loading error:", error);
			});
	}, []);

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const isLoggedIn = useCallback(() => {
		const accessToken = localStorage.getItem("accessToken");
		if (accessToken) {
			const decodedToken = jwt_decode(accessToken);
			const expirationTime = decodedToken.exp * 1000;
			const isExpired = Date.now() > expirationTime;
			if (isExpired) {
				authDispatch({ type: "LOGOUT" });
				localStorage.setItem("user", null);
				removeToken();
				return false;
			}
			return true;
		}
		return false;
	}, [authDispatch]);

	useEffect(() => {
		const checkAuthentication = () => {
			if (!isLoggedIn()) {
				navigate("/", { replace: true });
			}
		};

		const intervalId = setInterval(checkAuthentication, 60000);
		return () => clearInterval(intervalId);
	}, [navigate, isLoggedIn]);
	
	const getRoutes = (allRoutes) => {
		return allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse); // Return the result of recursive call
			}

			if (route.route) {
				return (
					<Route
						exact
						path={route.route}
						element={
							route.protected && !isLoggedIn() ? (
								<Navigate to="/" replace />
							) : (
								route.component
							)
						}
						key={route.key}
					/>
				);
			}

			// You also need to return this fallback Route if no match
			return <Route path="*" element={<Navigate to="/" replace />} />;
		});
	};

	const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	);

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CssBaseline />
			{layout === "dashboard" && (
				<>
					<Sidenav
						color={sidenavColor}
						brand={(transparentSidenav && !darkMode) || whiteSidenav ? "" : ""}
						brandName="PB Monitor"
						routes={sideBarData}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					<Configurator />
					{configsButton}
				</>
			)}
			{layout === "vr" && <Configurator />}
			<Routes>
				{getRoutes(routes)}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</ThemeProvider>
	);
}
