import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import DataTable from "utilities/Tables/DataTable";
import { Icon, Menu, MenuItem, IconButton, CircularProgress } from "@mui/material";

import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { toast } from "react-toastify";
import { useAuth } from "context/auth";
import NewClinic from "components/create-clinic";
import { deleteClinic, getAllClinics } from "services/clinicService";
import { useNavigate } from "react-router-dom";
import EditCLinic from "components/edit-clinic";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NewGroup from "components/create-group";
import SearchBar from "components/search-bar";

const SwalDialog = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

function Clinics() {
	const { user } = useAuth();
	const [clinics, setClinics] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [openEditModal, setOpenEditModal] = React.useState(false);
	const [openAddModal, setOpenAddModal] = React.useState(false);
	const [clinicData, setClinicData] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	const [date, setDate] = React.useState("");
	const navigate = useNavigate();

	const handleClose = () => {
		setOpen(false);
	};

	const fetchClinics = async () => {
		setLoading(true);
		try {
			const res = await getAllClinics(keyword, date);
			const { data } = res.data;
			setClinics(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	const onSearch = async (entry) => {
		setLoading(true);
		try {
			setKeyword(entry);
			const res = await getAllClinics(entry, date);
			const { data } = res.data;
			setClinics(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log("Search Error", error.message);
		}
	};

	React.useEffect(() => {
		fetchClinics();
	}, []);

	const editClinic = async (data) => {
		setOpenEditModal(true);
		setClinicData(data);
	};

	const addNewGroup = async (data) => {
		setOpenAddModal(true);
		setClinicData(data);
	};

	const handleAddClinic = () => {
		setOpen(!open);
	};

	const deleteClinicHandler = async (cid) => {
		const result = await SwalDialog.fire({
			title: "Confirm Delete",
			text: "You are about to Delete this clinic",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});

		if (result.isConfirmed) {
			try {
				const res = await deleteClinic(cid);
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchClinics();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire(
					"Error",
					error.message || "Unexpected error occurred",
					"error",
				);
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "Clinic not deleted", "info");
		}
	};

	const Group = ({ name }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDTypography
				display="block"
				variant="button"
				fontWeight="medium"
				ml={1}
				lineHeight={1}
			>
				{name}
			</MDTypography>
		</MDBox>
	);

	const DropdownMenu = ({ itemId, clinic }) => {
		const [anchorEl, setAnchorEl] = React.useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleEdit = () => {
			editClinic(clinic);
			handleCloseMenu();
		};

		const handleAddGroup = () => {
			addNewGroup(clinic);
			handleCloseMenu();
		};

		const handleDelete = () => {
			deleteClinicHandler(itemId);
			handleCloseMenu();
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem onClick={() => navigate(`/clinic-details/${clinic._id}`)}>
						<IconButton>
							<Icon>visibility</Icon>
						</IconButton>
						View
					</MenuItem>
					{["Admin", "Super-Admin", "State-Manager", "Tech"].includes(
						user?.role,
					) &&
						<MenuItem onClick={() => handleEdit()}>
							<IconButton>
								<Icon>edit</Icon>
							</IconButton>
							Edit
						</MenuItem>
					}
					{["Admin", "Super-Admin", "State-Manager", "Tech", "Coordinator"].includes(
						user?.role,
					) &&
						<MenuItem onClick={() => handleAddGroup()}>
							<IconButton>
								<Icon>group</Icon>
							</IconButton>
							Add Group
						</MenuItem>
					}
					{["Admin", "Super-Admin", "State-Manager", "Tech"].includes(
						user?.role,
					) &&
						<MenuItem onClick={() => handleDelete()}>
							<IconButton>
								<Icon>delete</Icon>
							</IconButton>
							Delete
						</MenuItem>
					}
				</Menu>
			</>
		);
	};

	const columns = [
		{ Header: "name", accessor: "name", width: "30%", align: "left" },
		{ Header: "location", accessor: "location" },
		{ Header: "total groups", accessor: "total_groups", align: "center" },
		{ Header: "status", accessor: "status", align: "center" },
		{
			Header: "actions",
			accessor: "actions",
			align: "center",
		},
	];

	const rows = clinics.map((row) => {
		return {
			name: <Group name={row.name} />,
			location: (
				<MDTypography
					component="a"
					variant="button"
					color="text"
					fontWeight="medium"
				>
					{row.location.state}
				</MDTypography>
			),

			total_groups: (
				<MDTypography
					component="a"
					variant="button"
					color="text"
					fontWeight="medium"
				>
					{row.groups?.length}
				</MDTypography>
			),
			status: (
				<MDBox ml={-1}>
					<MDBadge
						badgeContent={row.status}
						color={row.status === "Active" ? "success" : "dark"}
						variant="gradient"
						size="sm"
					/>
				</MDBox>
			),
			actions: <DropdownMenu itemId={row._id} clinic={row} />,
		};
	});

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<NewClinic open={open} handleClose={handleClose} updateData={fetchClinics} />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" color="white">
									Law Clinics
								</MDTypography>
								{["Admin", "Super-Admin", "Tech", "Staff"].includes(user?.role) && (
									<MDButton variant="outlined" onClick={handleAddClinic}>
										Add New Law Clinic
									</MDButton>
								)}
							</MDBox>
							<MDBox pt={3}>
								{loading ? (
									<MDBox display="flex" justifyContent="center" alignItems="center">
										<MDTypography variant="h6">
											<MDBox
												display="flex"
												justifyContent="center"
												alignItems="center"
											>
												<CircularProgress color="info" />
											</MDBox>{" "}
											Loading...
										</MDTypography>
									</MDBox>
								) : null}
								{rows?.length === 0 ? (
									<MDBox display="flex" justifyContent="center" alignItems="center">
										<MDTypography variant="h6">No Records Found</MDTypography>
									</MDBox>
								) : (
									<>
										<MDBox px={2} display="flex" justifyContent="space-between">
											<div></div>
											<SearchBar
												onSearch={onSearch}
												searchTerm={keyword}
												setSearchTerm={setKeyword}
											/>
										</MDBox>
										<MDBox>
											<DataTable
												table={{ columns, rows }}
												isSorted={false}
												entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
												showTotalEntries={true}
												noEndBorder
											/>
										</MDBox>
									</>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>

				<EditCLinic
					open={openEditModal}
					handleClose={() => setOpenEditModal(false)}
					clinicData={clinicData}
					updateTable={fetchClinics}
				/>
				<NewGroup
					open={openAddModal}
					handleClose={() => setOpenAddModal(false)}
					clinicData={clinicData}
					updateData={fetchClinics}
				/>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Clinics;
