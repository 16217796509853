import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";

function YearPicker({ selectedYear, onYearChange }) {
	const handleYearChange = (date) => {
		if (date && typeof date.$y === "number") {
			const year = date.$y;
			onYearChange(year);
		}
	};

	return (
		<MDBox mx={2} mt={2} py={3} px={2}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					views={["year"]}
					label="Select Year"
					value={new Date(selectedYear, 0, 1)} // Set the selected year as the initial value
					onChange={handleYearChange}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
		</MDBox>
	);
}

export default YearPicker;
