import httpService from "./httpService";

export const addCLinic = async (form) => {
	try {
		const response = await httpService.post("/clinic/create", form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllClinics = async (keyword, date) => {
	let url = `/clinic/fetch/all`;
	const params = [];
	try {
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}
		if (date) {
			params.push(`dateString=${date}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const res = await httpService.get(url);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllTheUserInAClinic = async (id) => {
	try {
		const res = await httpService.get(`/clinic/users/all/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getClinicGroups = async (id) => {
	try {
		const res = await httpService.get(`/clinic/fetch/groups/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewClinic = async (id) => {
	try {
		const res = await httpService.get(`/clinic/${id}`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteClinic = async (id) => {
	try {
		const res = await httpService.delete(`/clinic/${id}/delete`);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateClinic = async (id, form) => {
	try {
		const res = await httpService.put(`/clinic/${id}/update`, form);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
