import React, {useState, useEffect} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ReportsLineChart from "utilities/Charts/LineCharts/ReportsLineChart";
import { getCaseReviewData, getConferenceData, getDetaineeData } from "services/analyticsService";

// Data
// const sampleData =  {
// 	conferences: {
// 		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
// 		datasets: {
// 			label: "Case Conferences",
// 			data: [250, 40, 300, 320, 500, 350, 200, 230, 500, 400, 300, 200],
// 		},
// 	},
// 	reviews: {
// 		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
// 		datasets: {
// 			label: "Case Reviews",
// 			data: [750, 40, 300, 320, 500, 350, 200, 230, 500, 400, 300, 200],
// 		},
// 	},
// 	detainees: {
// 		labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
// 		datasets: {
// 			label: "Client Performance",
// 			data: [50, 40, 300, 220, 500, 250, 400, 230, 500, 400, 300, 200],
// 		},
// 	},
// };

function Overview({state, year}) {
	const [conferenceData, seConferenceData] = useState({});
	const [reviewData, setReviewData] = useState({});
	const [detaineeData, setDetaineeData] = useState({});
	
	useEffect(() => {
		const fetchConferenceData = async () => {
			try {
				const res = await getConferenceData(year, state)
				const { data } = res;
				seConferenceData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchConferenceData();
	}, [year, state]);

	useEffect(() => {
		const fetchReviewData = async () => {
			try {
				const res = await getCaseReviewData(year, state);
				const { data } = res;
				setReviewData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchReviewData();
	}, [year, state]);

	useEffect(() => {
		const fetchDetaineeData = async () => {
			try {
				const res = await getDetaineeData(year, state);
				const { data } = res;
				setDetaineeData(data);
			} catch (error) {
				console.log("ERROR", error.message);
			}
		};
		fetchDetaineeData();
	}, [year, state]);

	return (
		<MDBox mt={4.5}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<ReportsLineChart
							color="success"
							title="Case Conference"
							description="Case Conference Chart"
							date={year}
							chart={conferenceData}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<ReportsLineChart
							color="dark"
							title="Case Review"
							description="Case Review Chart"
							date={year}
							chart={reviewData}
						/>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<MDBox mb={3}>
						<ReportsLineChart
							color="info"
							title="Detainee/Client Intake"
							description="Detainee/Client Chart"
							date={year}
							chart={detaineeData}
						/>
					</MDBox>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default Overview;
