import { useCallback, useEffect, useState } from "react";
import {
	Grid,
	Card,
	Icon,
	Menu,
	MenuItem,
	IconButton,
	CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";
import YearPicker from "components/year-picker";
import { deleteCaseUploadReport } from "services/caseUploadService";
import { useNavigate } from "react-router-dom";
import DataTable from "utilities/Tables/DataTable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LocationPicker from "components/location-picker";
import { safeDateFormat } from "utils";
import { getAllCaseUploadReport } from "services/caseUploadService";
import SearchBar from "components/search-bar";
import { useAuth } from "context/auth";

const SwalDialog = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

function CaseUpload() {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [reportData, setReportData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState("");

	useEffect(() => {
		if (user.role === "State-Manager") {
			setQuery(user.state);
		}
		if (
			["Coordinator", "Group-Head", "Clinician", "Lawyer", "PDSS"].includes(
				user.role,
			)
		) {
			setQuery(user.clinicName);
		}
	}, [user]);

	const fetchReports = useCallback(async () => {
		setLoading(true);
		try {
			const res = await getAllCaseUploadReport("", "", query, "");
			const { data } = res.data;
			setReportData(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	}, [query]);

	useEffect(() => {
		fetchReports();
	}, [fetchReports]);

	const deleteHandler = async (cid) => {
		const result = await SwalDialog.fire({
			title: "Confirm Delete",
			text: "You are about to Delete this report",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});

		if (result.isConfirmed) {
			try {
				const res = await deleteCaseUploadReport(cid);
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchReports();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire(
					"Error",
					error.message || "Unexpected error occurred",
					"error",
				);
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "Report not deleted", "info");
		}
	};

	const Member = ({ name, identifier }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography display="block" variant="button" fontWeight="medium">
					{name}
				</MDTypography>
				<MDTypography variant="caption">{identifier}</MDTypography>
			</MDBox>
		</MDBox>
	);
	const DropdownMenu = ({ itemId, rep }) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleDelete = () => {
			deleteHandler(itemId);
			handleCloseMenu();
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem
						onClick={() => navigate(`/case-upload-report-details/${rep._id}`)}
					>
						<IconButton>
							<Icon>visibility</Icon>
						</IconButton>
						View
					</MenuItem>
					<MenuItem
						onClick={() =>
							navigate(`/case-upload-report-edit/${rep._id}`, {
								state: rep,
							})
						}
					>
						<IconButton>
							<Icon>edit</Icon>
						</IconButton>
						Edit
					</MenuItem>
					{[
						"Admin",
						"Super-Admin",
						"State-Manager",
						"Coordinator",
						"Group-Head",
						"Tech",
					].includes(user?.role) && (
						<MenuItem onClick={() => handleDelete()}>
							<IconButton>
								<Icon>delete</Icon>
							</IconButton>
							Delete
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const columns = [
		{
			Header: "RPDN ID",
			accessor: "rpdnId",
			width: "20%",
			align: "left",
		},
		{
			Header: "Case ID",
			accessor: "caseId",
			align: "left",
		},
		{
			Header: "Reporter",
			accessor: "nameOfReporter",
			align: "center",
		},
		{
			Header: "Date of Report",
			accessor: "date_of_report",
			align: "center",
		},
		// {
		//     Header: "Status",
		//     accessor: "status",
		//     align: "center",
		// },
		{ Header: "action", accessor: "action", align: "center" },
	];

	const rows = reportData.map((form) => {
		return {
			// rpdnId: <Member name={form.rpdnId} type={form.type} />,
			rpdnId: form.rpdnId || "",
			caseId: form.caseId || "",
			nameOfReporter: form.nameOfReporter || "",
			date_of_report: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{safeDateFormat(new Date(form?.createdAt), "dd/MM/yyyy")}
				</MDTypography>
			),
			action: <DropdownMenu userId={form?._id} rep={form} />,
		};
	});

	const currentYear = new Date().getFullYear();
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [updatedReportData, setUpdatedReportData] = useState([]);

	useEffect(() => {
		setUpdatedReportData(updatedReportData);
	}, [updatedReportData]);

	const handleYearChange = async (year) => {
		setSelectedYear(year);
		try {
			const res = await getAllCaseUploadReport("", "", query, year);
			const { data } = res.data;
			setUpdatedReportData(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	return (
		<MDBox pb={3}>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Card>
						<MDBox
							mx={2}
							mt={-3}
							py={3}
							px={2}
							variant="gradient"
							bgColor="info"
							borderRadius="lg"
							coloredShadow="info"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<MDTypography variant="h6" color="white">
								Case Upload Report
							</MDTypography>
							<MDButton
								variant="outlined"
								onClick={() => navigate("/case-upload-report")}
							>
								Add New Report
							</MDButton>
						</MDBox>
						<MDBox px={2} py={2} display="flex" justifyContent="space-between">
							<YearPicker
								selectedYear={selectedYear}
								onYearChange={handleYearChange}
							/>
							<SearchBar />{" "}
							{/* Search Bar  expecting onSearch, searchTerm and setSearchTerm  props */}
						</MDBox>
						<MDBox pt={3}>
							{loading ? (
								<MDBox display="flex" justifyContent="center" alignItems="center">
									<MDTypography variant="h6">
										<MDBox
											display="flex"
											justifyContent="center"
											alignItems="center"
											minHeight="100vh"
										>
											<CircularProgress color="info" />
										</MDBox>{" "}
										Loading...
									</MDTypography>
								</MDBox>
							) : null}
							{reportData.length === 0 ? (
								<MDBox px={3} py={2}>
									<MDTypography variant="h6" color="text">
										No report, start adding report
									</MDTypography>
								</MDBox>
							) : (
								<DataTable
									table={{ columns: columns, rows: rows }}
									isSorted={false}
									entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
									showTotalEntries={true}
									noEndBorder
								/>
							)}
						</MDBox>
					</Card>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default CaseUpload;
