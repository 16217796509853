import { format as formatDate } from "date-fns";

export function safeDateFormat(date, formatStr) {
    try {
        return formatDate(new Date(date), formatStr);
    } catch (error) {
        return "n/a";
    }
}

export const elipsis = (text, length) => {
	return text.length > length ? text.substring(0, length) + "..." : text;
};