import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { updateGroup } from "services/groupService";

function EditGroup(props) {
	const { handleClose, open, groupData, updateData } = props;
	const [name, setName] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setName(groupData?.name || "");
	}, [groupData]);

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		try {
			const res = await updateGroup(groupData._id, {
				name: name,
			});
			const { message } = res;
			toast(message, { type: "success", autoClose: 3000 });
			setIsLoading(false);
			updateData();
			handleClose();
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Edit {groupData?.name} Details</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<MDBox pt={2} px={2}>
						<MDInput
							type="text"
							variant="standard"
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<MDBox mt={4} mb={3}>
							<MDButton
								type="submit"
								variant="gradient"
								color="info"
								fullWidth
								disabled={isLoading}
								onClick={handleFormSubmit}
							>
								{isLoading ? "Loading..." : "Save"}
							</MDButton>
						</MDBox>
					</MDBox>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

export default EditGroup;
