import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/lab/Autocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { addCLinic } from "../../services/clinicService";
import { toast } from "react-toastify";
import NaijaStates from "naija-state-local-government";

function NewClinic(props) {
	const { handleClose, open, updateData } = props;
	const [name, setName] = useState("");
	const [state, setState] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleStateChange = (event, value) => {
		setState(value ? value.name : null);
	};

	const stateData = NaijaStates.states().map((item, idx) => {
		return {
			id: idx,
			name:
				item === "FCT"
					? "Federal Capital Territory"
					: item === "Federal Capital Territory"
					? "Federal Capital Territory"
					: item + " State",
			value: item + " State",
		};
	});

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();
		if (!name) {
			toast("Name is needed", { type: "error", autoClose: 3000 });
			setIsLoading(false);
			return;
		}
		if (!state) {
			toast("State is needed", { type: "error", autoClose: 3000 });
			setIsLoading(false);
			return;
		}

		try {
			const res = await addCLinic({
				name,
				location: {
					state,
				},
			});

			const { message, status } = res;
			if (status === "success") {
				toast(message, { type: "success", autoClose: 3000 });
				setIsLoading(false);
				setName("")
				setState("")
				updateData();
				handleClose();
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setIsLoading(false);
			}
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>New Clinic</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div>
						<MDBox pt={2} px={2}>
							<MDBox mb={2} mt={2}>
								<MDInput
									type="text"
									label="Name of Law Clinic"
									variant="standard"
									fullWidth
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</MDBox>
							<MDBox mb={2}>
								<Autocomplete
									value={stateData.find((item) => item.name === state) || null}
									onChange={handleStateChange}
									options={stateData}
									getOptionLabel={(option) => option.name}
									renderInput={(params) => (
										<MDInput
											{...params}
											type="text"
											label="Designated state"
											variant="standard"
											fullWidth
										/>
									)}
								/>
							</MDBox>

							<MDBox
								mt={4}
								mb={3}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<MDButton variant="gradient" color="dark" onClick={handleClose}>
									Close
								</MDButton>{" "}
								&nbsp; &nbsp;
								<MDButton
									type="submit"
									variant="gradient"
									color="info"
									disabled={isLoading}
									onClick={handleFormSubmit}
								>
									{isLoading ? "Loading..." : "Add Clinic"}
								</MDButton>
							</MDBox>
						</MDBox>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default NewClinic;
