import React from "react";

import Ditribution from "./Ditribution";
import WeeklySummary from "./WeeklySummary";
import YearlySummary from "./YearlySummary";

function AnalyticsView({ state, year }) {

	return (
		<>
			<Ditribution state={state} year={year} />
			<WeeklySummary state={state} year={year} />
			<YearlySummary state={state} year={year} />
		</>
	);
}

export default AnalyticsView;
