import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { updateClinic } from "../../services/clinicService";
import { toast } from "react-toastify";

function EditCLinic(props) {
	const { handleClose, open, clinicData, updateTable } = props;
	const [name, setName] = useState("");
	const [state, setState] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setName(clinicData?.name || "");
		setState(clinicData?.location.state || "");
	}, [clinicData]);

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		try {
			const res = await updateClinic(clinicData._id, {
				name: name,
				location: {
					state: state,
				},
			});
			const { message } = res;
			toast(message, { type: "success", autoClose: 3000 });
			setIsLoading(false);
			updateTable();
			handleClose();
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Edit {clinicData?.name} Details</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<MDBox pt={2} px={2}>
						<MDInput
							type="text"
							variant="standard"
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>

						<MDBox mb={2} mt={2}>
							<MDInput
								type="text"
								variant="standard"
								fullWidth
								value={state}
								readOnly={true}
								disabled={true}
								onChange={(e) => setState(e.target.value)}
							/>
						</MDBox>
						<MDBox mt={4} mb={3}>
							<MDButton
								type="submit"
								variant="gradient"
								color="info"
								fullWidth
								disabled={isLoading}
								onClick={handleFormSubmit}
							>
								{isLoading ? "Loading..." : "Save"}
							</MDButton>
						</MDBox>
					</MDBox>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

export default EditCLinic;
