import httpService from "./httpService";

export const loginUser = async (userData) => {
	try {
		const res = await httpService.post("/auth/signin", userData);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
export const userVerify = async (userData) => {
	try {
		const res = await httpService.post("/auth/verify-signin", userData);
		return res.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
