import httpService from "./httpService";

export const createCaseFile = async (form) => {
	try {
		const response = await httpService.post(`/case-files/create`, form);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllCaseFiles = async (page, limit, keyword, date) => {
	let url = `/case-files/fetch/all`;
	const params = [];

	try {
		if (page) {
			params.push(`page=${encodeURIComponent(page)}`);
		}
		if (limit) {
			params.push(`limit=${encodeURIComponent(limit)}`);
		}
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}

		if (date) {
			params.push(`year=${encodeURIComponent(date)}`);
		}
		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}

		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getAllMyCaseFiles = async (keyword, date) => {
	let url = `/case-files/my/all`;
	const params = [];

	try {
		if (keyword) {
			params.push(`keyword=${encodeURIComponent(keyword)}`);
		}
		if (date) {
			params.push(`dateString=${date}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}

		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const deleteCaseFile = async (id) => {
	try {
		const response = await httpService.delete(`/case-files/${id}/delete`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateCaseFile = async (id, payload) => {
	try {
		const response = await httpService.put(`/case-files/${id}/update`, payload);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const updateCaseStatus = async (id, payload) => {
	try {
		const response = await httpService.put(`/case-files/${id}/status`, payload);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const viewCaseFile = async (id) => {
	try {
		const response = await httpService.get(`/case-files/view/${id}`);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
