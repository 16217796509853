import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-law.jpg";
import { toast } from "react-toastify";
import { loginUser, userVerify } from "services/authService";
import {
    updateLocalAccessToken,
    updateLocalRefreshToken,
} from "services/tokenService";
import { useAuth } from "context/auth";

function Basic() {
    const { dispatch } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [loginInput, setLoginInput] = useState("");
    const [password, setPassword] = useState("");
    const [isVerify, setIsVerify] = useState(false);

    const otp = new URLSearchParams(window.location.search).get("otp");

    useEffect(() => {
        if (otp) {
            setPassword(otp);
            setIsVerify(true);
        }
    }, [otp]);

    useEffect(() => {
        window.localStorage.removeItem("currentTabIndex"); // Resets the report form tabs index
    }, []);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleSigninRequest = async (event) => {
        event.preventDefault();
        if (!loginInput) {
            toast.error("Please fill the required field", { autoClose: 3000 });
            return;
        }
        setIsLoading(true);

        try {
            const res = await loginUser({ loginInput });

            console.log("res", res);

            if (res.status === "success") {
                setIsVerify(true);
                toast.success(res.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error(error.message, { autoClose: 3000 });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOTPVerify = async () => {
        if (!password) {
            toast.error("Please enter the OTP", { autoClose: 3000 });
            return;
        }

        setIsVerifying(true);

        try {
            const res = await userVerify({ password });

            const { userData, access_token, refresh_token } = res.data;

            updateLocalAccessToken(access_token);
            updateLocalRefreshToken(refresh_token);
            dispatch({ type: "LOGIN", payload: userData });
            navigate("/dashboard", { replace: true });
            console.log("see modal", userData);
        } catch (error) {
            toast.error(error.message, { autoClose: 3000 });
        } finally {
            setIsVerifying(false);
        }
    };

    useEffect(() => {
        if (isVerify && password.length === 6) {
            handleOTPVerify();
        }
        // eslint-disable-next-line
    }, [password, isVerify]);

    const handleKeyDown = (event) => {
        if (isVerify && event.key === "Enter") {
            handleOTPVerify();
        } else if (!isVerify && event.key === "Enter") {
            handleSigninRequest(event);
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={1}
                    >
                        PB Monitor
                    </MDTypography>
                    <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={1}
                    >
                        {!isVerify ? "Sign in" : "OTP Verification"}
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox
                        component="form"
                        role="form"
                        onKeyDown={handleKeyDown}
                    >
                        <MDBox mb={2}>
                            {!isVerify ? (
                                <TextField
                                    type="text"
                                    label="Member Email"
                                    fullWidth
                                    value={loginInput}
                                    onChange={(e) =>
                                        setLoginInput(e.target.value)
                                    }
                                />
                            ) : (
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    label="Password"
                                    fullWidth
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityIcon />
                                                    ) : (
                                                        <VisibilityOffIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            {!isVerify ? (
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    disabled={isLoading}
                                    onClick={handleSigninRequest}
                                >
                                    {isLoading ? "Requesting..." : "Sign in"}
                                </MDButton>
                            ) : (
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    disabled={isVerifying}
                                    onClick={handleOTPVerify}
                                >
                                    {isVerifying ? "Verifying..." : "Verify"}
                                </MDButton>
                            )}
                        </MDBox>
                        {isVerify && (
                            <MDBox textAlign="center">
                                <MDTypography variant="button" color="text">
                                    Didn't get OTP? &nbsp;
                                    <MDTypography
                                        component={Link}
                                        onClick={() => setIsVerify(false)}
                                        variant="button"
                                        color="info"
                                        fontWeight="medium"
                                        textGradient
                                    >
                                        &nbsp; Request new one
                                    </MDTypography>
                                </MDTypography>
                            </MDBox>
                        )}
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Basic;
