import Autocomplete from "@mui/lab/Autocomplete";
import MDBox from "components/MDBox";
import NaijaStates from "naija-state-local-government";
import MDInput from "components/MDInput";

function StatePicker({ selectedState, onStateChange }) {
	const handleStateChange = (event, value) => {
		onStateChange(value ? value.name : null);
	};

	const stateData = NaijaStates.states().map((item, idx) => {
		return {
			id: idx,
			name:
				item === "FCT"
					? "Federal Capital Territory"
					: item === "Federal Capital Territory"
					? "Federal Capital Territory"
					: item + " State",
			value: item + " State",
		};
	});

	return (
		<MDBox mx={2} mt={2} py={1} px={2}>
			<Autocomplete
				value={stateData.find((item) => item.name === selectedState) || null}
				onChange={handleStateChange}
				options={stateData}
				getOptionLabel={(option) => option.name}
				renderInput={(params) => (
					<MDInput
						{...params}
						type="text"
						label="Select state"
						variant="standard"
						fullWidth
					/>
				)}
			/>
		</MDBox>
	);
}

export default StatePicker;
