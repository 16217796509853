import { Card, Container, Grid, Typography } from '@mui/material';
import Editor from 'components/Editor';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import React, { useState } from 'react'
import html2pdf from 'html2pdf.js';
import MDButton from 'components/MDButton';


const DetaineeBlank = () => {
    const [clinicianRecommendation, setClinicianRecommendation] = useState("");
  const [supervisorRecommendation, setSupervisorRecommendation] = useState("");
  const [caseId, setCaseId] = useState("");
  const [description, setDescription] = useState("");
  const [placeOfInterview, setPlaceOfInterview] = useState("");
  const [dateOfInterview, setDateOfInterview] = useState(Date.now());
  const [timeOfInterview, setTimeOfInterview] = useState("");
  const [custodialCenter, setCustodialCenter] = useState({
    name: "",
    location: "",
  });
  const [clientInformation, setClientInformation] = useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    address: "",
    employmentStatus: "",
    incomeLevel: "",
    anyPreviousLegalRep: "",
    consentToLegalRep: "",
    reason: "",
  });
  const [legalIssues, setLegalIssues] = useState({
    caseType: "",
    desiredOutcome: "",
    description: "",
    dateOfArrest: "",
    placeOfArrest: "",
    arrestingAgency: "",
    prosecutingAgency: "",
    firstPlaceOfDetention: "",
    bailGranted: "",
    whyStillInCustody: "",
    firstDateInCourt: "",
    lastAdjournedDate: "",
    nextDateInCourt: "",
    court: "",
    courtLocation: "",
    nameOfJudgeOrMagistrate: "",
    nameOfProsecutor: "",
    priorLegalRepresentation: "",
    previousAttemptToResolve: "",
  });
  const [additionalInformation, setAdditionalInformation] = useState({
    factsOfTheCase: "",
    conflictsOfInterest: "",
    clientSignature: "",
    translation: "",
  });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [formStep, setFormStep] = useState(1);



    const handleDownloadPDF = () => {

    const element = document.getElementById('hidden-content');
    const opt = {
      margin: 1,
      filename:'detainee.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <>
    <div style={{ display: 'none' }}>
        <div id="hidden-content">
         <MDBox pt={6} pb={3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Detainee Form
                  </MDTypography>
                </MDBox>

                <MDBox pt={4} pb={3}>
                  <Container>
                    <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                      {formStep === 1
                        ? "Step 1: General and Client Information"
                        : "Step 2: Legal Issues"}
                    </Typography>

                    
                        {/* General */}
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Section A: General
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div>
                              
                                  <MDInput
                                    type="text"
                                    label="Location State"
                                    variant="standard"
                                    fullWidth
                                  />
                             
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Custodial Center Name"
                              fullWidth
                              value={custodialCenter.name}
                              onChange={(e) =>
                                setCustodialCenter({
                                  ...custodialCenter,
                                  name: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Latitude"
                              fullWidth
                              value={latitude}
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Longitude"
                              fullWidth
                              value={longitude}
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Place of interview"
                              fullWidth
                              value={placeOfInterview}
                              onChange={(e) =>
                                setPlaceOfInterview(e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Date of Interview"
                              fullWidth
                              value={dateOfInterview}
                              onChange={(e) =>
                                setDateOfInterview(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="time"
                              variant="standard"
                              label="Time of Interview"
                              fullWidth
                              value={timeOfInterview}
                              onChange={(e) =>
                                setTimeOfInterview(e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            
                              
                                <MDInput
                              
                                  type="text"
                                  label="Clinic Name"
                                  variant="standard"
                                  fullWidth
                                />
                              
                            
                          </Grid>

                          <Grid item xs={12} sm={6}>
                           
                              
                                <MDInput
                                  type="text"
                                  label="Clinician"
                                  variant="standard"
                                  fullWidth
                                />
                            
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ my: 2 }}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Section B: Client Information
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name"
                              fullWidth
                              value={clientInformation.name}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  name: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item sx={12} sm={6}>
                            <MDInput
                              type="email"
                              variant="standard"
                              label="Email"
                              fullWidth
                              value={clientInformation.email}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  email: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="tel"
                              variant="standard"
                              label="Phone"
                              fullWidth
                              value={clientInformation.phone}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Address"
                              fullWidth
                              value={clientInformation.address}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  address: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Age"
                              fullWidth
                              value={clientInformation.age}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  age: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Employment Status"
                              fullWidth
                              value={clientInformation.employmentStatus}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  employmentStatus: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Income Level"
                              fullWidth
                              value={clientInformation.incomeLevel}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  incomeLevel: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Any Previous Legal Representation"
                              fullWidth
                              value={clientInformation.anyPreviousLegalRep}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  anyPreviousLegalRep: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Consent to Legal Representation"
                              fullWidth
                              value={clientInformation.consentToLegalRep}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  consentToLegalRep: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="If No Consent, please state reason"
                              fullWidth
                              value={clientInformation.reason}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  reason: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                     

                      
                        {/* Legal Issues Group */}

                        <Grid container spacing={3} sx={{ my: 2 }}>
                          <Grid item xs={12}>
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              gutterBottom
                            >
                              Legal Issues
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12}>
                            <Editor
                              title={"Brief Description"}
                              placeholder={
                                "(What is the charge? Why was he/she arrested? A narration of the actual incidence"
                              }
                              content={legalIssues.description}
                              handleInputChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  description: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Date of Arrest"
                              fullWidth
                              value={legalIssues.dateOfArrest}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  dateOfArrest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Place of Arrest"
                              fullWidth
                              value={legalIssues.placeOfArrest}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  placeOfArrest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Arresting Agency"
                              fullWidth
                              value={legalIssues.arrestingAgency}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  arrestingAgency: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Prosecuting Agency"
                              fullWidth
                              value={legalIssues.prosecutingAgency}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  prosecutingAgency: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="First Place of Detention"
                              fullWidth
                              value={legalIssues.firstPlaceOfDetention}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  firstPlaceOfDetention: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Bail Granted"
                              fullWidth
                              value={legalIssues.bailGranted}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  bailGranted: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Why Still in Custody"
                              fullWidth
                              value={legalIssues.whyStillInCustody}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  whyStillInCustody: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="First Date in Court"
                              fullWidth
                              value={legalIssues.firstDateInCourt}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  firstDateInCourt: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Last Adjourned Date"
                              fullWidth
                              value={legalIssues.lastAdjournedDate}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  lastAdjournedDate: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Next Date in Court"
                              fullWidth
                              value={legalIssues.nextDateInCourt}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nextDateInCourt: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Court"
                              fullWidth
                              value={legalIssues.court}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  court: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Court Location"
                              fullWidth
                              value={legalIssues.courtLocation}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  courtLocation: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name of Judge or Magistrate"
                              fullWidth
                              value={legalIssues.nameOfJudgeOrMagistrate}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nameOfJudgeOrMagistrate: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name of Prosecutor"
                              fullWidth
                              value={legalIssues.nameOfProsecutor}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nameOfProsecutor: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Prior Legal Representation"
                              fullWidth
                              value={legalIssues.priorLegalRepresentation}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  priorLegalRepresentation: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Previous Attempt to Resolve"
                              fullWidth
                              value={legalIssues.previousAttemptToResolve}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  previousAttemptToResolve: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ mt: 4 }}>
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              gutterBottom
                            >
                              Additional Information
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Facts of the Case"
                              fullWidth
                              value={additionalInformation.factsOfTheCase}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  factsOfTheCase: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Conflicts of Interest"
                              fullWidth
                              value={additionalInformation.conflictsOfInterest}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  conflictsOfInterest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Editor
                              title={"Clinician Recommendation"}
                              content={clinicianRecommendation}
                              handleInputChange={(e) =>
                                setClinicianRecommendation(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Editor
                              title={"Supervisor Recommendation"}
                              content={supervisorRecommendation}
                              handleInputChange={(e) =>
                                setSupervisorRecommendation(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                  

                    
                  </Container>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        </div>
        </div>
        <MDButton
        type="button"
        variant="outlined"
        onClick={handleDownloadPDF}
      >
        Download Detainee Form
      </MDButton>
    </>
  );

};

export default DetaineeBlank;