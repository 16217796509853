import httpService from "./httpService";

export const handleReportDownload = async (url, filename) => {
    try {
        const fileBlob = await httpService.get(url);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(fileBlob);
        link.download = filename;
        link.click();
    } catch (error) {
        console.error("Download error:", error.message);
    }
};
