import React, { createContext, useReducer, useEffect, useContext } from "react";

// Initial state
const INITIAL_STATE = {
	user: JSON.parse(localStorage.getItem("user")) || null,
	isAuthenticated: !!localStorage.getItem("user"),
};

// Reducer
const authReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			return {
				...state,
				user: action.payload,
				isAuthenticated: true,
			};
		case "UPDATE":
			return {
				...state,
				user: action.payload,
				isAuthenticated: true,
			};
		case "LOGOUT":
			return {
				...state,
				user: null,
				isAuthenticated: false,
			};
		default:
			return state;
	}
};

// Create context
const AuthContext = createContext(INITIAL_STATE);

// Provider component
const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);

	useEffect(() => {
		localStorage.setItem("user", JSON.stringify(state.user));
	}, [state]);

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	);
};

// Custom hook to use the AuthContext
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
