import { useState } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { toast } from "react-toastify";
import { addGroup } from "services/groupService";

function NewGroup(props) {
	const { handleClose, open, updateData, clinicData } = props;
	const [name, setName] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();
		if (!name) {
			toast("Name is needed", { type: "error", autoClose: 3000 });
			setIsLoading(false);
			return;
		}

		try {
			const res = await addGroup(clinicData._id, {
				name,
				clinic: clinicData._id,
			});

			const { message, status } = res;
			if (status === "success") {
				toast(message, { type: "success", autoClose: 3000 });
				setIsLoading(false);
				setName("");
				updateData();
				handleClose();
			} else {
				toast(message, { type: "error", autoClose: 3000 });
				setIsLoading(false);
			}
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>New Group to: {clinicData?.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div>
						<MDBox pt={2} px={2}>
							<MDBox mb={2} mt={2}>
								<MDInput
									type="text"
									label="Group Name"
									variant="standard"
									fullWidth
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</MDBox>
							<MDBox
								mt={4}
								mb={3}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<MDButton
									variant="gradient"
									color="dark"
									onClick={handleClose}
								>
									Close
								</MDButton>{" "}
								&nbsp; &nbsp;
								<MDButton
									type="submit"
									variant="gradient"
									color="info"
									disabled={isLoading}
									onClick={handleFormSubmit}
								>
									{isLoading ? "Loading..." : "Add Group"}
								</MDButton>
							</MDBox>
						</MDBox>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default NewGroup;
