import { useState, useEffect } from "react";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card } from "@mui/material";
import { toast } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { createDetaineeReport } from "services/detaineeFormService";
import { getAllClinics } from "../../services/clinicService";
import Editor from "components/Editor";
import { useNavigate } from "react-router-dom";
import PrintableAndDownloadable from "components/print-download";

function CaseReviewForm() {
	const [caseId, setCaseId] = useState("");
	const [note, setNote] = useState("");
	const [courtHearing, setCourtHearing] = useState({
		nameOfCourt: "",
		nameOfJudgeOrMagistrate: "",
		matterSlatedFor: "",
		wentAsSlated: "",
		reason: "",
		outcome: "",
		nextAdjournmentDate: "",
		reasonForAdjournment: "",
	});
	const [activity, setActivity] = useState({
		place: "",
		type: "",
	});
	const [externalParticipants, setExternalParticipants] = useState([]);
	const [internalParticipants, setInternalParticipants] = useState([]);
	const [nextStep, setNextStep] = useState("");
	const [locationState, setLocationState] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [clinics, setClinics] = useState([]);
	const [selectedClinic, setSelectedClinic] = useState(null);
	const navigate = useNavigate();

	const stateData = NaijaStates.states().map((item, idx) => {
		return {
			id: idx,
			name:
				item === "FCT"
					? "Federal Capital Territory"
					: item === "Federal Capital Territory"
					? "Federal Capital Territory"
					: item + " State",
			value: item + " State",
		};
	});

	const fetchClinics = async () => {
		try {
			const res = await getAllClinics();
			const { data } = res.data;
			setClinics(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	useEffect(() => {
		fetchClinics();
	}, []);

	const handleClinicChange = (event, newValue) => {
		setSelectedClinic(newValue);
	};

	const handleStateChange = (event, value) => {
		setLocationState(value ? value.name : null);
	};

	const fetchLocation = () => {
		if (!navigator.geolocation) {
			toast("Geolocation is not supported by this browser.", {
				type: "error",
				autoClose: 3000,
			});
			return;
		}

		// show loader
		setLoading(true);

		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				setLatitude(latitude);
				setLongitude(longitude);
				reverseGeocode(latitude, longitude);
				setLoading(false);
			},
			(error) => {
				toast("Unable to retrieve your location", {
					type: "error",
					autoClose: 3000,
				});
				setLoading(false);
			},
		);
	};

	const reverseGeocode = (latitude, longitude) => {
		const geocoder = new window.google.maps.Geocoder();
		const latlng = new window.google.maps.LatLng(latitude, longitude);

		geocoder.geocode({ location: latlng }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					const addressComponents = results[0].address_components;
					const state = addressComponents.find((item) =>
						item.types.includes("administrative_area_level_1"),
					);
					setLocationState(state.long_name || "Unknown State");
				} else {
					setLocationState("Please select a state");
				}
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		fetchLocation();
	}, []);

	const handleFormClear = () => {
		// Reset form
		setCaseId("");
		setNote("");
		setCourtHearing({
			nameOfCourt: "",
			nameOfJudgeOrMagistrate: "",
			matterSlatedFor: "",
			wentAsSlated: "",
			reason: "",
			outcome: "",
			nextAdjournmentDate: "",
			reasonForAdjournment: "",
		});
		setActivity({
			place: "",
			type: "",
		});
		setExternalParticipants([]);
		setInternalParticipants([]);
		setNextStep("");
		setLocationState("");
		setLatitude("");
		setLongitude("");
		setSelectedClinic(null);
	};

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		const formData = {
			location: {
				state: locationState,
				latitude,
				longitude,
			},
			clinicName: selectedClinic?.name,
		};

		try {
			const res = await createDetaineeReport(formData);
			const { message, status } = res;
			if (status === "success") {
				toast(message, { type: "success", autoClose: 3000 });
				handleFormClear();
				setIsLoading(false);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		handleFormClear();
		window.localStorage.setItem("currentTabIndex", 3);
		navigate(-1);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<PrintableAndDownloadable filename="casereviewform.pdf">
				<MDBox pt={6} pb={3}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography
										variant="h6"
										color="white"
										fontWeight="medium"
										textTransform="capitalize"
									>
										Case Review Form
									</MDTypography>
								</MDBox>
								<MDBox pt={6} pb={3}>
									<MDBox px={4}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<div>
													<Autocomplete
														value={
															stateData.find((item) => item.name === locationState) || null
														}
														onChange={handleStateChange}
														options={stateData}
														getOptionLabel={(option) => option.name}
														renderInput={(params) => (
															<MDInput
																{...params}
																type="text"
																label="Location State"
																variant="standard"
																fullWidth
															/>
														)}
													/>
												</div>
											</Grid>
											<Grid item xs={12} sm={6}>
												<MDInput
													type="text"
													variant="standard"
													label="Latitude"
													fullWidth
													value={latitude}
													readOnly
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<MDInput
													type="text"
													variant="standard"
													label="Longitude"
													fullWidth
													value={longitude}
													readOnly
												/>
											</Grid>

											<Grid item xs={12} sm={6}>
												<MDBox mb={2}>
													<MDInput
														type="text"
														variant="standard"
														label="RPDN ID"
														fullWidth
														value={caseId}
														onChange={(e) => setCaseId(e.target.value)}
													/>
												</MDBox>
											</Grid>
											<Grid item xs={12} sm={6}>
												<MDBox mb={2} mt={3}>
													<Autocomplete
														value={selectedClinic}
														onChange={handleClinicChange}
														options={clinics || []}
														getOptionLabel={(option) => (option.name ? option.name : "")}
														renderInput={(params) => (
															<MDInput
																{...params}
																type="text"
																label="Clinic Name"
																variant="standard"
																fullWidth
															/>
														)}
													/>
												</MDBox>
											</Grid>
										</Grid>

										{/* Activity */}
										<MDBox mb={2} mt={3}>
											<MDTypography variant="h6" fontWeight="medium" gutterBottom>
												Activity
											</MDTypography>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Place of activity"
													fullWidth
													value={activity.place}
													onChange={(e) =>
														setActivity({ ...activity, place: e.target.value })
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Type of activity"
													fullWidth
													value={activity.type}
													onChange={(e) =>
														setActivity({ ...activity, type: e.target.value })
													}
												/>
											</MDBox>
										</MDBox>

										{/* Court Hearing Group */}
										<MDBox mb={2} mt={3}>
											<MDTypography variant="h6" fontWeight="medium" gutterBottom>
												Court Hearing
											</MDTypography>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="name of court"
													fullWidth
													value={courtHearing.nameOfCourt}
													onChange={(e) =>
														setCourtHearing({ ...courtHearing, nameOfCourt: e.target.value })
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Name of Judge or Magistrate"
													fullWidth
													value={courtHearing.nameOfJudgeOrMagistrate}
													onChange={(e) =>
														setCourtHearing({
															...courtHearing,
															nameOfJudgeOrMagistrate: e.target.value,
														})
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Matter Slated for"
													fullWidth
													value={courtHearing.matterSlatedFor}
													onChange={(e) =>
														setCourtHearing({
															...courtHearing,
															matterSlatedFor: e.target.value,
														})
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													type="text"
													variant="standard"
													label="Went as Slated "
													fullWidth
													value={courtHearing.wentAsSlated}
													onChange={(e) =>
														setCourtHearing({ ...courtHearing, wentAsSlated: e.target.value })
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<Editor
													title={"Reason"}
													content={courtHearing.reason}
													handleInputChange={(e) =>
														setCourtHearing({ ...courtHearing, reason: e.target.value })
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<Editor
													title={"Outcome"}
													content={courtHearing.outcome}
													handleInputChange={(e) =>
														setCourtHearing({ ...courtHearing, outcome: e.target.value })
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													type="date"
													variant="standard"
													label="Next Adjournment Date"
													fullWidth
													value={courtHearing.nextAdjournmentDate}
													onChange={(e) =>
														setCourtHearing({
															...courtHearing,
															nextAdjournmentDate: e.target.value,
														})
													}
												/>
											</MDBox>
											<MDBox mb={2}>
												<Editor
													title={"Reason for Adjournmnent"}
													content={courtHearing.reasonForAdjournment}
													handleInputChange={(e) =>
														setCourtHearing({
															...courtHearing,
															reasonForAdjournment: e.target.value,
														})
													}
												/>
											</MDBox>
										</MDBox>

										<MDBox mb={2}>
											<Editor
												title={"Note"}
												content={note}
												handleInputChange={(e) => setNote(e.target.value)}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"Next Step"}
												content={nextStep}
												handleInputChange={(e) => setNextStep(e.target.value)}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"External Participants"}
												content={externalParticipants}
												handleInputChange={(e) => setExternalParticipants(e.target.value)}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Editor
												title={"Internal Participants"}
												content={internalParticipants}
												handleInputChange={(e) => setInternalParticipants(e.target.value)}
											/>
										</MDBox>

										<MDBox mt={3} sx={{ display: "flex", justifyContent: "center" }}>
											<MDButton
												type="submit"
												variant="gradient"
												color="info"
												disabled={isLoading}
												onClick={handleFormSubmit}
											>
												{isLoading ? "Loading..." : "Submit"}
											</MDButton>
											&nbsp;&nbsp;
											<MDButton
												variant="gradient"
												color="warning"
												onClick={() => handleFormClear()}
											>
												Clear
											</MDButton>
											<MDButton
												sx={{ ml: 1 }}
												variant="gradient"
												color="error"
												onClick={handleCancel}
											>
												Cancel
											</MDButton>
										</MDBox>
									</MDBox>
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</PrintableAndDownloadable>
			<Footer />
		</DashboardLayout>
	);
}

export default CaseReviewForm;
