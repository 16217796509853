import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { updateCaseFile } from "services/caseFileService";

function EditCaseFile(props) {
	const { handleClose, open, updateCaseFiles, selectedCase } = props;
	const [rpdnId, setRpdnId] = useState("");
	const [itemId, setItemId] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setRpdnId(selectedCase?.rpdnId);
		setItemId(selectedCase?._id);
	}, [selectedCase]);

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		try {
			const res = await updateCaseFile(itemId, {
				rpdnId: rpdnId,
			});
			const { message, status } = res;
			toast(message, { type: status, autoClose: 3000 });
			updateCaseFiles();
			handleClose();
			setIsLoading(false);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Edit Case File Number</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<MDBox pt={2} px={2}>
						<MDBox mb={2}>
							<MDInput
								label="Case File Number"
								value={rpdnId}
								onChange={(e) => setRpdnId(e.target.value)}
								fullWidth
							/>
						</MDBox>

						<MDBox mt={4} mb={3}>
							<MDButton
								type="submit"
								variant="gradient"
								color="info"
								fullWidth
								disabled={isLoading}
								onClick={handleFormSubmit}
							>
								{isLoading ? "Loading..." : "Save"}
							</MDButton>
						</MDBox>
					</MDBox>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default EditCaseFile;
