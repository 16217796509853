import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import MDTextareaRoot from "./MDTextareaRoot";


const MDTextarea = forwardRef(({ error, success, disabled, ...rest }, ref) => {
	return (
		<MDTextareaRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
	);
});

MDTextarea.propTypes = {
	error: PropTypes.bool,
	success: PropTypes.bool,
	disabled: PropTypes.bool,
};

MDTextarea.defaultProps = {
	error: false,
	success: false,
	disabled: false,
};

export default MDTextarea;
