import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { requestNewPassword } from "services/authService";

function Basic() {
	const navigate = useNavigate();
	const [resetInput, setResetInput] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	// const handleFormSubmit = async (event) => {
	// 	event.preventDefault();
	// 	if (!resetInput) {
	// 		toast("Please fill the required field", {
	// 			type: "error",
	// 			autoClose: 3000,
	// 		});
	// 		return;
	// 	}

	// 	setIsLoading(true);

	// 	try {
	// 		const res = await requestNewPassword({
	// 			resetInput,
	// 		});
	// 		const { status, message } = res;

	// 		if (status === "success") {
	// 			navigate("/", { replace: true });
	// 			toast(message, { type: "success", autoClose: 3000 });
	// 		} else {
	// 			toast(message, { type: "error", autoClose: 3000 });
	// 		}
	// 	} catch (error) {
	// 		toast(error.message, { type: "error", autoClose: 3000 });
	// 		setIsLoading(false);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	return (
		<BasicLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						FOI Cohort Member
					</MDTypography>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						New Password Request
					</MDTypography>
				</MDBox>
				<MDBox mx={2} px={1} textAlign="center">
					<MDTypography variant="button" color="text">
						Enter your email into have your new password sent to you.
					</MDTypography>
				</MDBox>
				<MDBox pt={1} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								type="text"
								label="Email or Member ID"
								fullWidth
								value={resetInput}
								onChange={(e) => setResetInput(e.target.value)}
							/>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton
								variant="gradient"
								color="info"
								fullWidthdisabled={isLoading}
								// onClick={handleFormSubmit}
							>
								{isLoading ? "Loading..." : "Submit"}
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Go back to sign in?{" "}
								<MDTypography
									component={Link}
									to="/"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Click here
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</BasicLayout>
	);
}

export default Basic;
