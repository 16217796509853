import { useState } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { createCaseFile } from "services/caseFileService";

function NewCaseFile(props) {
	const { handleClose, open, updateCaseFiles } = props;
	const [rpdnId, setRpdnId] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		if (!rpdnId) {
			toast("Please fill all fields", { type: "error", autoClose: 3000 });
			setIsLoading(false);
			return;
		}

		try {
			const res = await createCaseFile({
				rpdnId,
			});
			const { message, status } = res;
			toast(message, { type: status, autoClose: 3000 });
			updateCaseFiles();
			handleClose();
			setIsLoading(false);
			setRpdnId("");
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Add a new Case File</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div>
						<MDBox pt={2} px={2}>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="RPDN ID"
									variant="standard"
									fullWidth
									value={rpdnId}
									onChange={(e) => setRpdnId(e.target.value)}
								/>
							</MDBox>
							<MDBox
								mt={4}
								mb={3}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<MDButton variant="gradient" color="dark" onClick={handleClose}>
									Close
								</MDButton>{" "}
								&nbsp; &nbsp;
								<MDButton
									type="submit"
									variant="gradient"
									color="info"
									disabled={isLoading}
									onClick={handleFormSubmit}
								>
									{isLoading ? "Loading..." : "Create Case File"}
								</MDButton>
							</MDBox>
						</MDBox>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default NewCaseFile;
