import { useState, useEffect } from "react";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card, Typography, Container } from "@mui/material";
import { toast } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { createDetaineeReport, viewDetaineeReport } from "services/detaineeFormService";
import { getAllClinics } from "../../services/clinicService";
import Editor from "components/Editor";
import { set } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { getAllUsers } from "services/userService";
import PrintableAndDownloadable from "components/print-download";

function DetaineeReportView() {
    const { id } = useParams();	
  const [clinicianRecommendation, setClinicianRecommendation] = useState("");
  const [supervisorRecommendation, setSupervisorRecommendation] = useState("");
  const [caseId, setCaseId] = useState("");
  const [description, setDescription] = useState("");
  const [placeOfInterview, setPlaceOfInterview] = useState("");
  const [dateOfInterview, setDateOfInterview] = useState(Date.now());
  const [timeOfInterview, setTimeOfInterview] = useState("");
  const [custodialCenter, setCustodialCenter] = useState({
    name: "",
    location: "",
  });
  const [clientInformation, setClientInformation] = useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    address: "",
    employmentStatus: "",
    incomeLevel: "",
    anyPreviousLegalRep: "",
    consentToLegalRep: "",
    reason: "",
  });
  const [legalIssues, setLegalIssues] = useState({
    caseType: "",
    desiredOutcome: "",
    description: "",
    dateOfArrest: "",
    placeOfArrest: "",
    arrestingAgency: "",
    prosecutingAgency: "",
    firstPlaceOfDetention: "",
    bailGranted: "",
    whyStillInCustody: "",
    firstDateInCourt: "",
    lastAdjournedDate: "",
    nextDateInCourt: "",
    court: "",
    courtLocation: "",
    nameOfJudgeOrMagistrate: "",
    nameOfProsecutor: "",
    priorLegalRepresentation: "",
    previousAttemptToResolve: "",
  });
  const [additionalInformation, setAdditionalInformation] = useState({
    factsOfTheCase: "",
    conflictsOfInterest: "",
    clientSignature: "",
    translation: "",
  });
  const [locationState, setLocationState] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [formStep, setFormStep] = useState(1);
  const [clinicianName, setClinicianName] = useState("");
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();

  const stateData = NaijaStates.states().map((item, idx) => ({
    id: idx,
    name: item,
    value: item,
  }));

   const fetchData = async () => {
    try {
      const cRes = await viewDetaineeReport(id);
      const data = cRes.data;
      
    //   setClinics(data.nameOfLawClinic)
    //   setConferenceLocation(data.location.state);
    //   setConferenceDate(new Date(data.conferenceDate).toISOString().split('T')[0]);
    //   setParticipants(data.participants.join(", "));
    //   setGroupMemberParticipants(data.internalParticipants.join(", "));
    //   setListOfCasesDiscussed(data.listOfCasesDiscussed.join(", "));
    //   setOutcome(data.outcome);
    //   setNextSteps(data.nextSteps);
    //   setNote(data.note);
    //   setLatitude(data.location.latitude);
    //   setLongitude(data.location.longitude);
    //   setConfData(data);
      console.log('fetched detainees', data);
    } catch (error) {
      console.log("Case conference data fetch_error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  

  const fetchClinics = async () => {
    try {
      const res = await getAllClinics();
      const { data } = res.data;
      setClinics(data);
    } catch (error) {
      toast(error.message, { type: "error", autoClose: 3000 });
    }
  };

  const fetchMembers = async () => {
    try {
      const res = await getAllUsers();
      const { data } = res.data;
      console.log(data);
      setMembers(data);
    } catch (error) {
      toast(error.message, { type: "error", autoClose: 3000 });
    }
  };

  useEffect(() => {
    fetchClinics();
    fetchMembers();
  }, [id]);

  const handleClinicChange = (event, newValue) => {
    setSelectedClinic(newValue);
  };

  const handleStateChange = (event, value) => {
    setLocationState(value ? value.name : null);
  };

  const fetchLocation = () => {
    if (!navigator.geolocation) {
      toast("Geolocation is not supported by this browser.", {
        type: "error",
        autoClose: 3000,
      });
      return;
    }

    // show loader
    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        reverseGeocode(latitude, longitude);
        setLoading(false);
      },
      (error) => {
        toast("Unable to retrieve your location", {
          type: "error",
          autoClose: 3000,
        });
        setLoading(false);
      }
    );
  };

  const reverseGeocode = (latitude, longitude) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const state = addressComponents.find((item) =>
            item.types.includes("administrative_area_level_1")
          );
          setLocationState(state.long_name || "Unknown State");
        } else {
          setLocationState("Please select a state");
        }
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  const handleFormNext = () => {
    setFormStep(2);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFormBack = () => {
    setFormStep(1);
  };

  const handleFormClear = () => {
    // Reset form
    setClinicianRecommendation("");
    setSupervisorRecommendation("");
    setSelectedClinic(null);
    setDateOfInterview(Date.now());
    setTimeOfInterview("");
    setClientInformation({
      name: "",
      email: "",
      phone: "",
      age: "",
      address: "",
      employmentStatus: "",
      incomeLevel: "",
      anyPreviousLegalRep: "",
      consentToLegalRep: "",
      reason: "",
    });
    setLegalIssues({
      caseType: "",
      desiredOutcome: "",
      description: "",
      dateOfArrest: "",
      placeOfArrest: "",
      arrestingAgency: "",
      prosecutingAgency: "",
      firstPlaceOfDetention: "",
      bailGranted: "",
      whyStillInCustody: "",
      firstDateInCourt: "",
      lastAdjournedDate: "",
      nextDateInCourt: "",
      court: "",
      courtLocation: "",
      nameOfJudgeOrMagistrate: "",
      nameOfProsecutor: "",
      priorLegalRepresentation: "",
      previousAttemptToResolve: "",
    });
    setAdditionalInformation({
      factsOfTheCase: "",
      conflictsOfInterest: "",
      clientSignature: "",
      translation: "",
    });
    setLocationState("");
  };

  const handleCancel = () => {
    handleFormClear();
    navigate(-1);
  };

  const handleFormSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const formData = {
      location: {
        state: locationState,
        latitude,
        longitude,
      },
      custodialCenter,
      clinicName: selectedClinic?.name,
      clinicianName,
      description,
      placeOfInterview,
      dateOfInterview,
      timeOfInterview,
      clientInformation,
      legalIssues,
      additionalInformation,
      clinicianRecommendation,
      supervisorRecommendation,
    };

    try {
      const res = await createDetaineeReport(formData);
      const { message, status } = res;
      if (status === "success") {
        toast(message, { type: "success", autoClose: 3000 });
        handleFormClear();
        setIsLoading(false);
      } else {
        toast(message, { type: "error", autoClose: 3000 });
      }
    } catch (error) {
      toast(error.message, { type: "error", autoClose: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PrintableAndDownloadable filename="detaineeform.pdf">
        <MDBox pt={6} pb={3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Detainee Form
                  </MDTypography>
                </MDBox>

                <MDBox pt={4} pb={3}>
                  <Container>
                    <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                      {formStep === 1
                        ? "Step 1: General and Client Information"
                        : "Step 2: Legal Issues"}
                    </Typography>

                    {formStep === 1 && (
                      <>
                        {/* General */}
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Section A: General
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div>
                              <Autocomplete
                                value={
                                  stateData.find(
                                    (item) => item.name === locationState
                                  ) || null
                                }
                                onChange={handleStateChange}
                                options={stateData}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <MDInput
                                    {...params}
                                    type="text"
                                    label="Location State"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Custodial Center Name"
                              fullWidth
                              value={custodialCenter.name}
                              onChange={(e) =>
                                setCustodialCenter({
                                  ...custodialCenter,
                                  name: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Latitude"
                              fullWidth
                              value={latitude}
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Longitude"
                              fullWidth
                              value={longitude}
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Place of interview"
                              fullWidth
                              value={placeOfInterview}
                              onChange={(e) =>
                                setPlaceOfInterview(e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Date of Interview"
                              fullWidth
                              value={dateOfInterview}
                              onChange={(e) =>
                                setDateOfInterview(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="time"
                              variant="standard"
                              label="Time of Interview"
                              fullWidth
                              value={timeOfInterview}
                              onChange={(e) =>
                                setTimeOfInterview(e.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              value={selectedClinic}
                              onChange={handleClinicChange}
                              options={clinics || []}
                              getOptionLabel={(option) =>
                                option.name ? option.name : ""
                              }
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  type="text"
                                  label="Clinic Name"
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              options={members || []}
                              value={clinicianName}
                              onChange={(event, newValue) =>
                                setClinicianName(newValue)
                              }
                              getOptionLabel={(option) =>
                                option.name ? option.name : ""
                              }
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  type="text"
                                  label="Clinician"
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ my: 2 }}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Section B: Client Information
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name"
                              fullWidth
                              value={clientInformation.name}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  name: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item sx={12} sm={6}>
                            <MDInput
                              type="email"
                              variant="standard"
                              label="Email"
                              fullWidth
                              value={clientInformation.email}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  email: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="tel"
                              variant="standard"
                              label="Phone"
                              fullWidth
                              value={clientInformation.phone}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Address"
                              fullWidth
                              value={clientInformation.address}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  address: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Age"
                              fullWidth
                              value={clientInformation.age}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  age: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Employment Status"
                              fullWidth
                              value={clientInformation.employmentStatus}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  employmentStatus: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Income Level"
                              fullWidth
                              value={clientInformation.incomeLevel}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  incomeLevel: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Any Previous Legal Representation"
                              fullWidth
                              value={clientInformation.anyPreviousLegalRep}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  anyPreviousLegalRep: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Consent to Legal Representation"
                              fullWidth
                              value={clientInformation.consentToLegalRep}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  consentToLegalRep: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="If No Consent, please state reason"
                              fullWidth
                              value={clientInformation.reason}
                              onChange={(e) =>
                                setClientInformation({
                                  ...clientInformation,
                                  reason: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {formStep === 2 && (
                      <>
                        {/* Legal Issues Group */}

                        <Grid container spacing={3} sx={{ my: 2 }}>
                          <Grid item xs={12}>
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              gutterBottom
                            >
                              Legal Issues
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12}>
                            <Editor
                              title={"Brief Description"}
                              placeholder={
                                "(What is the charge? Why was he/she arrested? A narration of the actual incidence"
                              }
                              content={legalIssues.description}
                              handleInputChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  description: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Date of Arrest"
                              fullWidth
                              value={legalIssues.dateOfArrest}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  dateOfArrest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Place of Arrest"
                              fullWidth
                              value={legalIssues.placeOfArrest}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  placeOfArrest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Arresting Agency"
                              fullWidth
                              value={legalIssues.arrestingAgency}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  arrestingAgency: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Prosecuting Agency"
                              fullWidth
                              value={legalIssues.prosecutingAgency}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  prosecutingAgency: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="First Place of Detention"
                              fullWidth
                              value={legalIssues.firstPlaceOfDetention}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  firstPlaceOfDetention: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Bail Granted"
                              fullWidth
                              value={legalIssues.bailGranted}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  bailGranted: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Why Still in Custody"
                              fullWidth
                              value={legalIssues.whyStillInCustody}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  whyStillInCustody: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="First Date in Court"
                              fullWidth
                              value={legalIssues.firstDateInCourt}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  firstDateInCourt: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Last Adjourned Date"
                              fullWidth
                              value={legalIssues.lastAdjournedDate}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  lastAdjournedDate: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MDInput
                              type="date"
                              variant="standard"
                              label="Next Date in Court"
                              fullWidth
                              value={legalIssues.nextDateInCourt}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nextDateInCourt: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Court"
                              fullWidth
                              value={legalIssues.court}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  court: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Court Location"
                              fullWidth
                              value={legalIssues.courtLocation}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  courtLocation: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name of Judge or Magistrate"
                              fullWidth
                              value={legalIssues.nameOfJudgeOrMagistrate}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nameOfJudgeOrMagistrate: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Name of Prosecutor"
                              fullWidth
                              value={legalIssues.nameOfProsecutor}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  nameOfProsecutor: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Prior Legal Representation"
                              fullWidth
                              value={legalIssues.priorLegalRepresentation}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  priorLegalRepresentation: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Previous Attempt to Resolve"
                              fullWidth
                              value={legalIssues.previousAttemptToResolve}
                              onChange={(e) =>
                                setLegalIssues({
                                  ...legalIssues,
                                  previousAttemptToResolve: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ mt: 4 }}>
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              gutterBottom
                            >
                              Additional Information
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Facts of the Case"
                              fullWidth
                              value={additionalInformation.factsOfTheCase}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  factsOfTheCase: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              variant="standard"
                              label="Conflicts of Interest"
                              fullWidth
                              value={additionalInformation.conflictsOfInterest}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  conflictsOfInterest: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Editor
                              title={"Clinician Recommendation"}
                              content={clinicianRecommendation}
                              handleInputChange={(e) =>
                                setClinicianRecommendation(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Editor
                              title={"Supervisor Recommendation"}
                              content={supervisorRecommendation}
                              handleInputChange={(e) =>
                                setSupervisorRecommendation(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {/* Buttons */}
                    <MDBox
                      px={2}
                      mt={5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {formStep === 1 ? (
                        <>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            onClick={handleFormNext}
                          >
                            Next
                          </MDButton>
                          <MDButton
                            sx={{ ml: 2 }}
                            variant="gradient"
                            color="warning"
                            onClick={() => handleFormClear()}
                          >
                            Clear
                          </MDButton>
                        </>
                      ) : (
                        <>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            onClick={handleFormBack}
                          >
                            Back
                          </MDButton>
                          <MDButton
                            sx={{ ml: 2 }}
                            type="submit"
                            variant="gradient"
                            color="success"
                            disabled={isLoading}
                            onClick={handleFormSubmit}
                          >
                            {isLoading ? "Loading..." : "Submit"}
                          </MDButton>
                          <MDButton
                            sx={{ ml: 2 }}
                            variant="gradient"
                            color="error"
                            onClick={() => handleCancel()}
                          >
                            Cancel
                          </MDButton>
                        </>
                      )}
                    </MDBox>
                  </Container>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
</PrintableAndDownloadable>
      <Footer />
    </DashboardLayout>
  );
}

export default DetaineeReportView;
