// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import ComplexStatisticsCard from "utilities/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "context/auth";
import {
	getActiveLocations,
	getTotalClinics,
	getTotalMembers,
} from "services/analyticsService";
import AnalyticsView from "./AnalyticsView";
import YearPicker from "components/year-picker";
import StatePicker from "components/State-picker";
import { Divider } from "@mui/material";

function Analytics() {
	const { user } = useAuth();
	const [clinicData, setClinicData] = useState(null);
	const [locationData, setLocationData] = useState(null);
	const [memberData, setMemberData] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const [state, setState] = useState("");

	const fetchData = useCallback(async () => {
		try {
			const [cRes, lRes, mRes] = await Promise.all([
				getTotalClinics(year, state),
				getActiveLocations(year, state),
				getTotalMembers(year, state),
			]);

			const totalMembers = mRes.data.groups.reduce(
				(total, group) => total + group.memberCount,
				0,
			);

			setLocationData(lRes.data);
			setClinicData(cRes?.data?.totalClinics)
			setMemberData(totalMembers);
		} catch (error) {
			console.log("Dashboard data fetch_error:", error);
		}
	}, [year, state]);

	useEffect(() => {
		fetchData();
	}, [year, state, fetchData]);

	return (
		<>
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox my={1} px={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<MDBox color="text" fontWeight="light">
								<YearPicker selectedYear={year} onYearChange={setYear} />
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<MDBox color="text" fontWeight="light">
								<StatePicker selectedState={state} onStateChange={setState} />
							</MDBox>
						</Grid>
					</Grid>
					<Divider />
				</MDBox>
				<MDBox py={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="account_balance"
									title="LAW CLINICS"
									count={clinicData ? clinicData : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of clinics",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="person"
									title="Members (Lawyers and Interns)"
									count={memberData ? memberData : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of members",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="location_on"
									title="Active Locations"
									count={locationData ? locationData.totalActiveLocations : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of active locations",
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<MDBox mt={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<AnalyticsView state={state} year={year} />
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
				<Footer />
			</DashboardLayout>
		</>
	);
}

export default Analytics;
